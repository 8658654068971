// Utils.js
import { /* v4 as uuidv4, NIL as NULL_UUID, */ validate as uuidValidate, version as uuidVersion, } from 'uuid';
import {DEFAULT_GA_SETTINGS} from '../../config/globals';
// import {GAExceptionTracking} from '../analytics/Analytics';

// ****************************** //
export const utils_getCurrentWindowLocationSlugPath = () => {
  const currentPath = window.location.pathname;
  // console.log("DBG: currentPath = " + currentPath);
  utils_DbgLogger("currentPath = " + currentPath);
  return currentPath;
}
// ****************************** //

// ****************************** //
export const utils_DbgLogger = (logMessage) => {
  console.log("DBG : " + logMessage);
}
// ****************************** //

// ****************************** //
export const utils_ErrorLogger = (errMessage, lFnName = '') => {
  console.log("ERR : " + lFnName + " : "+ errMessage );
  if (true === DEFAULT_GA_SETTINGS.SENDERRTOGA) { // send to GA tbd-todo
    // GAExceptionTracking(false, "", lFnName, "", "ERROR", errMessage); // tbd-todo
  }
}
// GAExceptionTracking = (isFatal, lUUID, lComponentName, lFunctionName, lOperation, lExceptionMessage) => {
// ****************************** //

// ****************************** //
export const utils_generateGaClientId = () => { // cid for GA
  var ts = Math.round(+new Date() / 1000.0);
  var rand;
  try {
    var uu32 = new Uint32Array(1);
    rand = crypto.getRandomValues(uu32)[0];
  } catch (e) {
    rand = Math.round(Math.random() * 2147483647);
  }
  return [rand, ts].join('.');
} // utils_generateGaClientId()
// ****************************** //


// ****************************** //
export const utils_UUIDValidateV4 = (uuid) => {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4; // true or false
}
// ****************************** //


// ****************************** //
export const utils_DecodeString = (lEncodedString) => {
  const decodedStr = new Buffer.from(lEncodedString, 'base64').toString();
  // console.log("DBG : Decoded = " + decodedStr);
  return decodedStr;
}
// ****************************** //

// Utils.js