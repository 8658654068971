// Home.jsx
// import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
// import MyGoogleMap from '../MyGoogleMap';

// export function HomePageContent() {
const HomePageContent = () => {
    return (
        <>
       <h1> Welcome to EVPITSTOP.IN</h1>
       <Typography>
           Stay Tuned - We are working harder to give you that pleasant journey on your EV.
       </Typography>
       <Typography paragraph>
                Click <Link to="/EVStations">here</Link> to see the list of charging stations. 
        </Typography>
        </>
    )
}; // HomePageContent
export default HomePageContent;


/*
 <div className="main-wrapper">
            <p>EV Fast Charge Stations*</p>
            <MyGoogleMap evstnsdata={tmpevstnsdata}></MyGoogleMap>
            </div>
*/
// Home.jsx
