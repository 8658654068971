// Products.jsx
// import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const ProductsPageContent = ({}) => {
// const ProductsPageContent = () => {
        return (
        <>
            <h1>Products And Services</h1>
            <h2>We will be listing EV Related Products and Services under this page.</h2>
            <Typography paragraph>
                Our motto is provide to provide high quality Products and Services to our Privleaged EV Users.
            </Typography>
            <Typography paragraph>
                We would like to hear from you on that Products and Accessorires that you may be interested for your EV.
            </Typography>
            <Typography paragraph>
                Page is still under construction. Please come back and vist us later.
                Or alternatively click <Link to="/Contactus">here</Link> to Submit your Product Request.
            </Typography>
        </>
    )
}; // Products
export default ProductsPageContent;
// Products.jsx
