// Analytics.js
// import ReactGA from 'react-ga'; // old ga
import ReactGA from "react-ga4";
import { DEFAULT_GA_SETTINGS } from '../../config/globals';
import { utils_DbgLogger /*, utils_ErrorLogger */ } from '../utils/Utils';
// ****************************** //

// ****************************** //
export const GAInit = () => {
  ReactGA.initialize(DEFAULT_GA_SETTINGS.DEFAULT_GA_TAGID, // 'G-2ZVGZLMY1B'
    {
      debug: DEFAULT_GA_SETTINGS.GADEBUG,
      debug_mode: DEFAULT_GA_SETTINGS.GADEBUG,
      
      gaOptions: {
        debug: DEFAULT_GA_SETTINGS.GADEBUG,
        debug_mode: DEFAULT_GA_SETTINGS.GADEBUG,
        testMode: DEFAULT_GA_SETTINGS.GADEBUG, // false,
        trackingId: DEFAULT_GA_SETTINGS.DEFAULT_GA_TAGID,
        clientId: DEFAULT_GA_SETTINGS.gaUUID,
        name: "www.evpitstop.in",
        siteSpeedSampleRate: 100,
        sampleRate: 100,
        alwaysSendReferrer: true,
        allowAnchor: true,
        // cookieName: "_EVPSGA-",
        cookieExpires: 63072000, // 2 years
        cookieUpdate: true,
        anonymizeIp: false,
        forceSSL: false,
        userId: DEFAULT_GA_SETTINGS.gaUUID,
        uuid: DEFAULT_GA_SETTINGS.gaUUID, // localStorage.getItem('uuid'), // TBD-TODO
        cid: DEFAULT_GA_SETTINGS.gaCID, // localStorage.getItem('uuid'), // TBD-TODO
        // cookieDomain: "none",
        // cookieFlags: 'None',
      }, // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
      gtagOptions: {
        // tbd-todo
      },
    }); // init
  // ReactGA.set({
  //   uuid: DEFAULT_GA_SETTINGS.gaUUID, // localStorage.getItem('uuid'), // TBD-TODO
  //   cid: DEFAULT_GA_SETTINGS.gaCID, // localStorage.getItem('uuid'), // TBD-TODO
  //   // Other relevant user information
  // });
}
// ****************************** //


// ****************************** //
// export const GAPageViewTracking = (location, uuid, cid) => {
export const GAPageViewTracking = () => {
  // const pageSlugUrl = location.pathname + location.search;
  const pageSlugUrl = window.location.pathname + window.location.search;
  // utils_DbgLogger("Current Slug = " + pageSlugUrl);

  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: pageSlugUrl });

  /*
    ReactGA.set({
      // uuid: DEFAULT_GA_SETTINGS.uuid, // localStorage.getItem('userName'), // TBD-TODO
      // cid: DEFAULT_GA_SETTINGS.cid,
      page: pageSlugUrl,
      // Other relevant user information
    });
    ReactGA.pageview(pageSlugUrl); // TBD-TODO
    // ReactGA.pageview(window.location.pathname + window.location.search);  // TBD-TODO 
    // ReactGA.pageview(location.pathname + location.search); // TBD-TODO
    */
  return (<div></div>); // return null; // TBD-TODO
}
// ****************************** //


// ****************************** //
/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const GAEventTracking = (category, action, label, value) => {

  // console.log("DBG : GA event:", category, ":", action, ":", label); // dbg

  // Send a custom event
  ReactGA.event({
    uuid: DEFAULT_GA_SETTINGS.gaUUID,
    cid: DEFAULT_GA_SETTINGS.gaCID,
    category: category,
    action: action,
    label: label, // optional
    value: 99, // value optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });

  /*
  ReactGA.event({
    nonInteraction: false, // not initiated by user
    uuid: DEFAULT_GA_SETTINGS.gaUUID,
    cid: DEFAULT_GA_SETTINGS.gaCID,
    category: category,
    action: action,
    label: label,
    value: value,
  }); */ 
};
// ****************************** //

// ****************************** //
/*
isFatal
Component
Function
Operation
UUID
Exception Message / Error Message
*/
// ****************************** //
/*
export const GAExceptionTracking = (isFatal, lUUID, lComponentName, lFunctionName, lOperation, lExceptionMessage) => {
  const lErrDesc = lUUID + "|" + lComponentName + "|" + lFunctionName + "|" + lOperation + "|" + lExceptionMessage;
  utils_DbgLogger("GAExceptionTracking : " + lErrDesc);
  ReactGA.exception({
    fatal: isFatal,
    description: lErrDesc,
  });
} // GAExceptionTracking()
*/
// ****************************** //


// ****************************** //
export const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};
// ****************************** //


// Analytics.js