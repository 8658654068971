// Autocomplete.js
import { DEFAULT_MAP_SETTINGS } from '../config/globals';
import React, { Component } from 'react';
import styled from 'styled-components';
import { GAEventTracking } from '../components/analytics/Analytics'; // GA
import { // Utils
    utils_DbgLogger, 
    // utils_ErrorLogger, utils_generateGaClientId, utils_UUIDValidateV4, utils_DecodeString
} from './utils/Utils';


const Wrapper = styled.div`
    margin: 5px 10px;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  text-align:center;
`;
// padding: 20px;

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.clearSearchBox = this.clearSearchBox.bind(this);
    }

    componentDidMount({ map, mapApi } = this.props) {
        const options = {
            // restrict your search to a specific type of result
            types: DEFAULT_MAP_SETTINGS.AUTOCOMPLETEOPTS_SRCH_SCOPE,
            // restrict your search to a specific country, or an array of countries
            componentRestrictions: DEFAULT_MAP_SETTINGS.AUTOCOMPLETEOPTS_CTRY_SCOPE, //  { country: ['in'] }, // ['gb', 'us']
        };
        this.autoComplete = new mapApi.places.Autocomplete(
            this.searchInput,
            options,
        );
        this.autoComplete.addListener('place_changed', this.onPlaceChanged);
        this.autoComplete.bindTo('bounds', map);
    }

    componentWillUnmount({ mapApi } = this.props) {
        mapApi.event.clearInstanceListeners(this.searchInput);
    }

    onPlaceChanged = ({ map, addplace } = this.props) => {
        const place = this.autoComplete.getPlace();

        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        addplace(place);
        this.searchInput.blur();
        // utils_DbgLogger("Place Typed By User = " + place); // DBG
/* {address_components: Array(5), adr_address: '<span class="locality">Tindivanam</span>, <span cl…1</span>, 
<span class="country-name">India</span>', formatted_address: 'Tindivanam, Tamil Nadu 604001, India', geometry: {…},
 icon: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png', …} */
        // GA GAEventTracking = (category, action, label, value)
        GAEventTracking("MAPS-CITY-SEARCH", place.formatted_address, place.geometry.location, place.adr_address);
    };

    clearSearchBox() {
        this.searchInput.value = '';
    }

    render() {
        return (
            <Wrapper>
                <input
                    className="search-input"
                    ref={(ref) => {
                        this.searchInput = ref;
                    }}
                    type="text"
                    onFocus={this.clearSearchBox}
                    placeholder="Type City or Town Name"
                />
            </Wrapper>
        );
    }
}

export default AutoComplete;