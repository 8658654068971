// index.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {reportWebVitals} from './components/analytics/Analytics';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals(); // reportWebVitals(console.log)) or send to an analytics endpoint https://bit.ly/CRA-vitals
// index.jsx