// globals.js
// import React from 'react';
import { NIL as NULL_UUID } from 'uuid';
// ****************************** //

// ****************************** //
export const DEFAULT_APP = {
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || 'dev', // 'production',
  WWWHOME: process.env.REACT_APP_WWWHOME, //  + ':' + process.env.REACT_APP_PORT, // || 'http://localhost:3002', // https://evpitstop.in/
  IMGSLUG: '/img',
  DEFAULTMARKERICON: 'https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg',
  LOCALSTORAGE_USER_KEYNAME: 'evpsuser',
  LOGGING : {
    MOD_DATA: false,
    MOD_MAPS_MARKER: false,
    MOD_MAPS_EVSTNPAGE: false,
  }
}
// ****************************** //



// ****************************** //
export const DEFAULT_MAP_SETTINGS = {
    DEFAULT_MAP_OPTIONS: {
      scrollwheel: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    },
    DEFAULT_CENTER: { lat: 12.037735, lng: 77.827745 }, // Coimbatore
    DEFAULT_ZOOM: 6,
    MARKER_SIZE: 35,
    PIXEL_OFFSET: {
      MARKER: {
        X: 0,
        Y: -35,
      },
    },
    DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
    AUTOCOMPLETEOPTS_SRCH_SCOPE: ['(cities)'], // ['address'],
    AUTOCOMPLETEOPTS_CTRY_SCOPE: { country: ['in'] }, // ['gb', 'us']
    GGLMAPSKEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  };
// ****************************** //

// export default DEFAULT_MAP_SETTINGS;


/*
// https://developers.google.com/maps/documentation/places/web-service/autocomplete
 https://stackoverflow.com/questions/8282026/how-to-limit-google-autocomplete-results-to-city-and-country-only
['address'],
['cities'],

Zooom - 6 - south india
// process.env.REACT_APP_GOOGLE_MAPS_API_KEY
REACT_APP_API_DATA_HOST=http://localhost:7000/
REACT_APP_GOOGLE_MAPS_API_KEY=AIzaSyC250gtKq8fUQFejhqH2Zu8PwXN02iRUWg
*/

// ****************************** //
export const DEFAULT_GA_SETTINGS = {
   DEFAULT_GA_TAGID: 'G-2ZVGZLMY1B',
   gaUUID: NULL_UUID,
   gaCID: '0000000000.0000000000',
   GADEBUG: false, // true, // false,
   SENDERRTOGA: true,
};
// ****************************** //

// ****************************** //
export const DEFAULT_API_DATA_SETTINGS = {
  EVSTNDATAHOST: process.env.REACT_APP_API_DATA_HOST,
  EVSTNGETGEOSTATESDATASLUG: '/getstates',
  EVSTNGETEVSTNDATABYSTATEIDSLUG: '/getevstninfo',
  EVSTNUSERSIGNINSLUG: '/signin',
};

if ((DEFAULT_API_DATA_SETTINGS.EVSTNDATAHOST === null) || (DEFAULT_API_DATA_SETTINGS.EVSTNDATAHOST === "")) {
  DEFAULT_API_DATA_SETTINGS.EVSTNDATAHOST ='https://evpitstop.in/d73800c95ebf45eb9191ccd659533a44'; // backup to live
}
// process.env.REACT_APP_API_DATA_HOST http://localhost:7000
// ****************************** //


// ****************************** //
export const DEFAULT_USEROBJ = 
{
  user: {
    userId: NULL_UUID,
    uuid: NULL_UUID,
    cid: '0000000000.0000000000',
    name: 'GuestUser 0000000000.0000000000',
    username: 'guestuser.0000000000.0000000000',
    isAdmin: false,
    isReturningUser: false,
    isLoggedIn: false,
    email: 'guestuser.0000000000.0000000000@evpitstopuser.com',
  },
  token: '',
  expiredAt: 0,
};
// ****************************** //

// ****************************** //
export const coookieConcentMessage = 'Our website uses cookies to enhance the user experience.';
// ****************************** //
// ****************************** //
// ****************************** //

// globals.js
