// Marker.js
// import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DEFAULT_APP } from '../config/globals';
const isLogging = DEFAULT_APP.LOGGING.MOD_MAPS_MARKER;


// transform: translate(-50%, -100%);


//   width: 50px;
//   height: 50px;
// background-size: contain;
// background-repeat: no-repeat;
// -webkit-user-select: none;
// -moz-user-select: none;
// -ms-user-select: none;
// -webkit-transform: translate(-50%,-50%);
// -ms-transform: translate(-50%,-50%);
// transform: translate(-50%,-50%);
// cursor: grab;

// top: 100%;
// left: 100%;
// "top-right"


// var markerImgIcon = "https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg"; // backup default marker icon hard coded
var markerImgIconDef = DEFAULT_APP.DEFAULTMARKERICON;
// markerImgIconDef = 'http://localhost:3002/img/google-maps-anchor-blue.png';
markerImgIconDef = 'https://www.gstatic.com/images/icons/material/system_gm/2x/place_gm_blue_24dp.png';
// ****** ALERT ****** CRITICAL *****
// WHEN I CHANGE THE LOCATION MARKER TO LOCAL COPY THEN IT SCREWS UP
// I WASTE ONE DAY
// SOMETHING RELATED TO IMAGE ATTRIBUTES AND HOW GOOGLE MAPS WORK WITH THEM INTERNALLY
// USE THE EXERNAL URLs FOR LOCATION MARKERS FOR NOW - UNTIL I FIND THE SOLUTION
const MarkerStylesDefault = styled.div`
background-image: url(${markerImgIconDef});
position: absolute;
width: 50px;
height: 50px;
background-size: contain;
background-repeat: no-repeat;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-webkit-transform: translate(-50%,-50%);
-ms-transform: translate(-50%,-50%);
transform: translate(-50%,-100%);
cursor: grab;
`;


const Marker = ({ text, onClick}) => (
    <MarkerStylesDefault
        alt={text}
        onClick={onClick}
    />
);

Marker.defaultProps = {
    onClick: null,
};

Marker.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
};

export default Marker;


/*
var EVPSMarkerImgIconRed = DEFAULT_APP.WWWHOME + DEFAULT_APP.IMGSLUG + '/' + 'marker_red.png';
const EVPSMarkerStylesRed = styled.div`
background-image: url(${EVPSMarkerImgIconRed});
position: absolute;
width: 50px;
height: 50px;
background-size: contain;
background-repeat: no-repeat;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-webkit-transform: translate(-50%,-50%);
-ms-transform: translate(-50%,-50%);
transform: translate(-50%,-100%);
cursor: grab;
`;



var EVPSMarkerImgIconGrey = DEFAULT_APP.WWWHOME + DEFAULT_APP.IMGSLUG + '/' + 'marker-grey.png';
const EVPSMarkerStylesGrey = styled.div`
background-image: url(${EVPSMarkerImgIconGrey});
position: absolute;
width: 50px;
height: 50px;
background-size: contain;
background-repeat: no-repeat;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-webkit-transform: translate(-50%,-50%);
-ms-transform: translate(-50%,-50%);
transform: translate(-50%,-100%);
cursor: grab;
`;

var EVPSMarkerImgIconYellow = DEFAULT_APP.WWWHOME + DEFAULT_APP.IMGSLUG + '/' + 'marker-yellow.png';
const EVPSMarkerStylesYellow = styled.div`
background-image: url(${EVPSMarkerImgIconYellow});
position: absolute;
width: 50px;
height: 50px;
background-size: contain;
background-repeat: no-repeat;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-webkit-transform: translate(-50%,-50%);
-ms-transform: translate(-50%,-50%);
transform: translate(-50%,-100%);
cursor: grab;
`;
*/
/*
The component styled.div with the id of "sc-hGbgdv" has been created dynamically.
You may see this warning because you've called styled inside another component.
To resolve this only create new StyledComponents outside of any render method and function component.
*/


/*
const markerStyle = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -100%)",
    'background-size': 'contain',
    'background-repeat': 'no-repeat',
};
*/

/*
const MyDiv = React.createClass({
    render: function() {
      const style = {
        color: 'white',
        fontSize: 200
      };
      
      return <div style={style}> Have a good and productive day! </div>;
    }
  });

const Marker = ({ text, onClick }) => (
    <Wrapper
        alt={text}
        onClick={onClick}
    />
);
*/


/*
// 'url(https://evpitstop.in/img/marker-green-ev.png)',
// background-image: url(${EVPSMarkerImgIconGreenURL});
// var EVPSMarkerImgIconGreenURL = DEFAULT_APP.WWWHOME + DEFAULT_APP.IMGSLUG + '/' + 'marker-green-ev.png';
const EVPSMarkerStylesGreen = styled.div`
'url(https://evpitstop.in/img/marker-green-ev.png)';
position: absolute;
width: 50px;
height: 50px;
background-size: contain;
background-repeat: no-repeat;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-webkit-transform: translate(-50%,-50%);
-ms-transform: translate(-50%,-50%);
transform: translate(-50%,-100%);
cursor: grab;
`;


const Markerr = ({ text, onClick}) => (
    <EVPSMarkerStylesGreen
        alt={text}
        onClick={onClick}
    />
);


// THIS HAS THE IN-TANDEM WITH EVSTNSNIPPET.JS : EVstnSnippet() 
function Marker3({ text, onClick, info }) {



    if ((info === undefined) || (info === null)) {
        if (isLogging) console.log("DBG : Inside Marker() Info Obj is null"); // DBG
        return (<div> <MarkerStylesDefault alt={text} onClick={onClick} /> </div>);
    } else if (info.st === true) { // status is active but not in use - use green marker
        if (isLogging) console.log("DBG : Inside Marker GREEN()"); // DBG
        // return (<EVPSMarkerStylesGreen alt={text} onClick={onClick} />);
        // return (<img style={markerStyle} src={EVPSMarkerImgIconGreenURL} alt={info.nm} />);
        return (
        <div style={{
            position: 'absolute',
            'width': '50px',
            'height': '50px',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
            '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            '-webkit-transform': "translate(-50%,-50%)",
            '-ms-transform': "translate(-50%,-50%)",
            'transform': "translate(-50%,-100%)",
            'cursor': 'grab',
            'background-image': 'url(https://evpitstop.in/img/marker-green-ev.png)',
        }} alt={text} onClick={onClick}  />
);

// onMouseOver={() => {}} - tbd
// http://localhost:3002/img/marker-green-ev.png
// https://evpitstop.in/img/marker-green-ev.png
// https://evpitstop.in/img/marker-red-ev.png


// tbd-todo change the url to localhost to evpitstop.in url

// http://localhost:3002/img/marker-green-ev.png
// https://icons8.com/icons/set/marker-storm--black <<<<<
// https://icons8.com/icons/set/marker-storm--white <<<<<<<<
// https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg
// http://localhost:3002/img/marker-green.png
// http://localhost:3002/img/marker-green.jpg
// https://stackoverflow.com/questions/43937887/dynamically-adding-markers-on-google-map-react <<<<<<
// https://stackoverflow.com/questions/26882177/react-js-inline-style-best-practices <<<<<
// https://stackoverflow.com/questions/50273232/how-to-add-dynamic-css-in-react-js <<<<

    } else if (info.st === false) { // status is NOT active but not in use - use RED  marker
        if (isLogging) console.log("DBG : Inside Marker RED()"); // DBG
        // return (<div> <EVPSMarkerStylesRed alt={text} onClick={onClick} /> </div>);



        <div style={{
            position: 'absolute',
            'width': '50px',
            'height': '50px',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
            '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            '-webkit-transform': "translate(-50%,-50%)",
            '-ms-transform': "translate(-50%,-50%)",
            'transform': "translate(-50%,-100%)",
            'cursor': 'grab',
            'background-image': 'url(https://evpitstop.in/img/marker-red-ev.png)',
        }} alt={text} onClick={onClick} onMouseOver={() => {}} />
        



    } else { // tbd add a condition active but busy - yello marker
        return (<div></div>); // safety belt 
    }

}
*/

/*
const Marker2 = ({ text, onClick, info }) => {
// function Marker({ text, onClick, info }) {
    // marker-grey.png marker-yellow.png
    // console.log("DBG : Inside Marker()"); // DBG
    // tbd-todo - this <marker> is called several times even if we do mouse over inside the maps window frame

    if ((info === undefined) || (info === null)) {
        console.log("DBG : Inside Marker() Info Obj is null"); // DBG
        return (<div> <MarkerStylesDefault alt={text} onClick={onClick} /> </div>);
    } else if (info.st === true) { // status is active but not in use - use green marker
        console.log("DBG : EVPSMarkerStylesGreen URL = " + EVPSMarkerImgIconGreenURL);
        // console.log("DBG : Inside Marker GREEN()"); // DBG
        return (<EVPSMarkerStylesGreen alt={text} onClick={onClick} />);
        // return (<img style={markerStyle} src={EVPSMarkerImgIconGreenURL} alt={info.nm} />);

    } else if (info.st === false) { // status is NOT active but not in use - use RED  marker
        console.log("DBG : Inside Marker RED()"); // DBG
        return (<div> <EVPSMarkerStylesRed alt={text} onClick={onClick} /> </div>);
    } else { // tbd add a condition active but busy - yello marker
        return (<div></div>); // safety belt 
    }

};
*/

/*
    markerImgIcon = "marker-grey.png";
    if (info.st === true) {
        markerImgIcon = "marker-green.png";
    } else {
        markerImgIcon = "marker-red.png";
    }
    markerImgIcon = DEFAULT_APP.WWWHOME + DEFAULT_APP.IMGSLUG + '/' + markerImgIcon;
    //  markerImgIcon = "https://evpitstop.in/img/" + markerImgIcon; // hard coded
    //    background-image: url(${markerImgIcon});
    markerImgIcon = ' background-image: url(' + markerImgIcon + '); '; // dynamically constructed marker icon url
    console.log("DBG : markerImgIcon = " + markerImgIcon);


    EVPSMarkerStyles += markerImgIcon + '\`';


    return (<div> <EVPSMarkerStyles alt={text} onClick={onClick} /> </div>); */

/*

 
  const Wrapper = styled.div`
    background-image: url(https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg);
    position: absolute;
      width: 50px;
      height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-100%);
    cursor: grab;
        `;

const Marker = ({ text, onClick }) => (
    <Wrapper
        alt={text}
        onClick={onClick}
    />
);
*/
// onClick={onClick}
// onClick={onMarkerClick}
//     onMarkerClick: PropTypes.func,



// https://www.robotwoods.com/dev/misc/bluecircle.png
// http://localhost:3200/img/curlocationmarker.png
// https://evpitstop.in/img/curlocationmarker.png

const CurrentLocationMarkerWrapper = styled.div`
    position: absolute;
    width: 50px;
    height: 50px;
    background-image: url(https://evpitstop.in/img/curlocationmarker.png);
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    cursor: grab;
`;


export const CurrentLocationMarker = ({ text, onClick }) => (
    <CurrentLocationMarkerWrapper
        alt={text}
        onClick={onClick}
    />
);

// onClick={onClick}
// onClick={onMarkerClick}
//     onMarkerClick: PropTypes.func,

CurrentLocationMarker.defaultProps = {
    onClick: null,
};

CurrentLocationMarker.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
};

