// Parners.jsx
// import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const ParnersPageContent = ({}) => {
// const ParnersPageContent = () => {
        return (
        <>
            <h1>Parners</h1>
            <Typography paragraph>
                We are interested to work with various Partners who can make the difference in the life of EV Customers.
            </Typography>
            <Typography paragraph>
                If you are interested in partnering with 
            </Typography>
            <Typography paragraph>
                Click <Link to="/Contactus">here</Link> and send your proposal on how we can partner and work together.
            </Typography>
        </>
    )
}; // Parners
export default ParnersPageContent;
// Parners.jsx
