// NavigationBar.tsx
import React, { useState } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Drawer, MenuList, MenuItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

//  import { FaChargingStation } from 'react-icons/fa';
// import { RiChargingPile2Fill, RiChargingPileLine, RiChargingPileFill, RiChargingPile2Line } from 'react-icons/ri';
// import { MdEvStation } from 'react-icons/md';
// import Icon from '@material-ui/core/Icon';
import EvStationIcon from '@material-ui/icons/EvStation';
// ****************************** //

// routes
// import Routes from './Routes'; MOVED ALL THE ROUTES INTO THIS FILE
// import { Router, BrowserRouter, Switch, Route } from 'react-router-dom';

// EVStations
// import EVStationsPageContent from '../Pages/EVStations';
// import AboutusPageContent from '../Pages/Aboutus';
// import HomePageContent from '../Pages/Home';
// import ContactusPageContent from '../Pages/Contactus';
// import { render } from '@testing-library/react';

// ****************************** //
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: 300,
    },
    fullList: {
      width: 'auto',
    },
  }),
);
// ****************************** //

// ****************************** //
export const Routes = [
  {
    path: '/',
    sidebarName: 'Home',
    autoRouteEnabled: true,
    // component: Home,
    // render={(props) => (<PropsPage {...props} title={`Props through render`} /> )},
  },
  {
    path: '/EVStations',
    sidebarName: 'EVStations',
    autoRouteEnabled: false,
    // component: EVStationsPageContent, // TBD-TODO EVStationsPageContent or EVStations ???
  },
  {
    path: '/Aboutus',
    sidebarName: 'Aboutus',
    autoRouteEnabled: true,
    // component: AboutusPageContent
  },
  {
    path: '/Contactus',
    sidebarName: 'Contactus',
    autoRouteEnabled: true,
    // component: ContactusPageContent
  },
  {
    path: '/Partners',
    sidebarName: 'Our Partners',
    autoRouteEnabled: true,
    // component: cOMPONENT
  },
  {
    path: '/Products',
    sidebarName: 'Products & Services',
    autoRouteEnabled: true,
    // component: cOMPONENT
  },
  {
    path: '/Terms',
    sidebarName: 'Terms & Policies',
    autoRouteEnabled: false,
    // component: Terms
  },

  
  /*
  {
    path: '/submitisssue',
    sidebarName: 'Report An Issue',
    autoRouteEnabled: true,
    // component: cOMPONENT
  },
  {
    path: '/',
    sidebarName: '',
    autoRouteEnabled: false,
    // component: cOMPONENT
  },*/

  // PluggedOut404PageContent
  // IssueSubmissionPageContent


]; // Routes[]
// ****************************** //



// ****************************** //
const NavigationBar: React.FC = (props: any) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (open: boolean) => ( event: React.KeyboardEvent | React.MouseEvent ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpen(open);
  };

  const activeRoute = (routeName: any) => {
    return props.location.pathname === routeName ? true : false;
  }

  
  const handleLogoClick = () => {
   /* onClick={() => {}}
    variant="outlined"
    component={Link}
    to={{
      pathname: `/`,
      search: location.search,
    }} */
   };

  /*

import { FaChargingStation } from 'react-icons/fa';
import { RiChargingPile2Fill, RiChargingPileLine, RiChargingPileFill, RiChargingPile2Line } from 'react-icons/ri';
import { MdEvStation } from 'react-icons/md';

{FaChargingStation} {MdEvStation} { RiChargingPile2Fill} {RiChargingPileLine} {RiChargingPileFill} {RiChargingPile2Line } 
  */

  return (
    <div>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>

            <IconButton
                aria-label="evpitstop.in logo"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleLogoClick}
                component={Link}
          to={{
            pathname: `/`
          }}
                >
                <EvStationIcon/>
              </IconButton>



              EVPitStop - Beta* 
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer classes={{ paper: classes.drawer }} open={isOpen} onClose={toggleDrawer(false)}>
        <div
          className={classes.fullList}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <MenuList>
            {Routes.map((prop, key) => {
              return (
                <NavLink to={prop.path} style={{ textDecoration: 'none' }} key={key}>
                  <MenuItem selected={activeRoute(prop.path)}>
                    <ListItemText primary={prop.sidebarName} />
                  </MenuItem>
                </NavLink>
              );
            })}
          </MenuList>
        </div>
      </Drawer>
    </div>
  );
};
// ****************************** //

export default withRouter(NavigationBar);
// ****************************** //
// NavigationBar.tsx