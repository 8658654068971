// evstnsnippet.js
// import React from 'react';


/*
info.location_id id
info.name nm

{
        id: evStnObj.evstn_id,
        nm: evStnObj.evstn_name,
        lt: evStnObj.l_geo_addr_postals[0].geoPostalAddr_lat,
        ln: evStnObj.l_geo_addr_postals[0].geoPostalAddr_lng,
        cy: evStnObj.l_geo_addr_postals[0].l_geoaddr_cities[0].geoCity_name,
        sn: evStnObj.l_geo_addr_postals[0].l_geoaddr_cities[0].l_geoaddr_states[0].geoState_name,
        dt: displayStatus,
        st: inserviceStatus,
      };
            <div className="map-details">ID : <span>{info.location_id}</span></div>
*/

// THIS HAS THE IN-TANDEM WITH LOCATIONINFOBOX.JS : LocationInfoBox() 
function EVstnSnippet({ info }) {

    if ((info === undefined) || (info === null)) {
        return <div className="info-wrapper"></div>; // safety belt
    }
       // console.log("DBG : Inside LocationInfoBox()"); // DBG
       var statusToDisplay = "";
       if (info.st === true) {
           statusToDisplay = "IN SERVICE";
       } else {
           statusToDisplay = "NOT IN SERVICE";
       }
       // Depending on the status I can add RED Color etc. future TBD-TODO

    return (
        <div className="info-wrapper">
            <div className="map-details">EVSTATION DETAILS</div>
            <div className="map-details">Location : <span> {info.cy}</span></div>
            <div className="map-details">Name     : <span> {info.nm}</span></div>
            <div className="map-details">Status   : <span> {statusToDisplay}</span></div>
        </div>
    );
} // EVstnSnippet


/*
        <div className="location-info" style={{height: "30vh", width: "50%"}}>
            <h2>EV Station Details</h2>
            <ul>
                <br/>
                <li>ID: <strong>{info.location_id}</strong></li>
                <li>TITLE: <strong>{info.name}</strong></li>
                <br/>
            </ul>
        </div>
        */
//                 <td onClick={()=> window.open(`${info.link}`, "_blank")}>EV Station Info</td>

export default EVstnSnippet;

/*
                <li>DESC: <strong>{info.description}</strong></li>
                <li>More Info : <strong>{info.link}</strong></li>

                                <a href={info.link}> Station Info</a>

                <td onClick={()=> window.open("someLink", "_blank")}>text</td>
                <td onClick={()=> window.open({info.link}, "_blank")}>EV Station Info</td>


*/
// evstnsnippet.js
