// Contactus.jsx
// import React from 'react';
// import { render } from 'react-dom';
// import ReactWhatsapp from 'react-whatsapp';
import Typography from '@material-ui/core/Typography';
// import { Link } from 'react-router-dom';

const ContactusPageContent = () => {
    // TBD-TODO - Build a form based submission
    return (
        <>
            <h1>Contact Us</h1>
            <Typography paragraph>
                We are just an email away to meet your needs.
            </Typography>
            <Typography paragraph>
                Please send an email to <b><a href="mailto: crm@evpitstop.in?subject=Enquiry from App">crm@evpitstop.in</a></b>
            </Typography>
            <Typography paragraph>
                if you have any questions or comments or suggestions or any specific needs.
            </Typography>
            <Typography paragraph>
                We are also looking for partners.
            </Typography>
            <Typography paragraph>
                You can also reachus through WhatsApp by <a style={{ display: "table-cell" }} href="https://wa.me/919482061540?text=Contact From Web EvPitStop.In" target="_blank">clicking here.</a>
            </Typography>
            <Typography paragraph>
                You can also reachus through Telegram by <a style={{ display: "table-cell" }} href="https://t.me/shsnllp?text=Contact From Web EvPitStop.In" target="_blank">clicking here.</a>
            </Typography>
        </>
    )
}; // ContactusPageContent

export default ContactusPageContent;

/*
https://api.whatsapp.com/send/?phone=919482061540&text=Contact+from+Web+App&app_absent=0

t.me/shsnllp

*/


// Contactus.jsx
