// EVStations.jsx
// https://github.com/shsngit/evstn2021sep
import { DEFAULT_APP, DEFAULT_MAP_SETTINGS } from '../../config/globals';
import { GAEventTracking } from '../analytics/Analytics';



// import { any } from 'prop-types';
import React, { Component } from 'react';
// import React, { Component, useState, useEffect, createRef } from 'react';
import GoogleMapReact from 'google-map-react';

// import Typography from '@material-ui/core/Typography';
// import { Link } from 'react-router-dom';
// import { PinDropSharp } from '@material-ui/icons';
// import MyGoogleMap from '../../components/MyGoogleMap';

import AutoComplete from '../Autocomplete';
import Marker, { CurrentLocationMarker } from '../Marker';
import LocationInfoBox from '../LocationInfoBox';
import EVstnSnippet from '../evstnsnippet';

// API Data
import { getEVStationDataByState } from '../data/GetData';
//import { useApiRequest, getEVGeoStatesNamesData, getEVStationDataByState } from '../data/GetData';

import { utils_DbgLogger, utils_ErrorLogger } from '../utils/Utils';
const isLogging = DEFAULT_APP.LOGGING.MOD_MAPS_EVSTNPAGE;
// import { Typography } from '@material-ui/core';


class EVStationsPageContent extends Component {
   
    constructor(props) {
        super(props);
        // this.setEVMapDisplay = this.setEVMapDisplay.bind(this);
        this.state = {
            center: DEFAULT_MAP_SETTINGS.DEFAULT_CENTER, // [],
            zoom: DEFAULT_MAP_SETTINGS.DEFAULT_ZOOM, // 12,
            mapApiLoaded: false,
            mapInstance: null,
            mapApi: null,
            geoCoder: null,
            // places: [],
            address: '',
            draggable: true,
            lat: null,
            lng: null,
            isvalidevstationsdata: false,
            evstationsdata: [],
            prevMarker: null,
            selectedMarker: null,
            showingInfoWindow: false,
            locationInfo: null,
            iwID: null,
            iwName: null,
            iWDetails: null,
            currentLocationLat: null,
            currentLocationLng: null,
            clickedOnChild: null,
            gglMapsKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            shopMapComponent: true,
            isEVStnDataReady: false
        };
        // let enStnsToDisplay = this.props.evstations || this.state.evstationsdata; // parent or child state
        var inMemEVSTNObj = this.checkAndFetchEVSTNData();



        // supportedgeostateids.map((stateid) => {utils_DbgLogger("DBG: " + stateid.nm)});
        // this.props.evstations.evstns.map((evstn) => {utils_DbgLogger("DBG: INCHILD = evstations = " +  evstn.location_id)});
        // this.state.evstations.evstns.map((evstn) => {utils_DbgLogger("DBG: INCHILD = evstations = " +  evstn.location_id)});
        // this.checkForValidEVSTATIONData();
    }
    // ****************************** //


    // ****************************** //
    checkAndFetchEVSTNData() {
        if(isLogging) utils_DbgLogger("Inside checkAndFetchEVSTNData() = "); // + this.props.evstations.evstns); // DBG

        let refetchedEVSTNData = this.props.evstations; // init with parent data

        // this.checkForValidEVSTATIONData();
        // if ((this.props.evstations === undefined) || (this.props.evstations === null)
        //    || (this.props.evstations.evstns === undefined) || (this.props.evstations.evstns.length <= 0)
        // ) {
        if ((refetchedEVSTNData === undefined) || (refetchedEVSTNData === null)
            || (refetchedEVSTNData.evstns === undefined) || (refetchedEVSTNData.evstns.length <= 0)
        ) {
            if(isLogging) utils_DbgLogger("ERR : No EVSTATIONS Data PASSED FROM PARENT : Need to refetch."); // DBG
            refetchedEVSTNData = this.fetchEVSTATIONSDATA(); // re-fetch data from API - null or actual data
            if ((refetchedEVSTNData === undefined) || (refetchedEVSTNData === null)) {
                utils_ErrorLogger("Unable to Fetch EVSTATION Data", "EVSTation|checkAndFetchEVSTNData");
            }

            this.setState({
                isEVStnDataReady: false,
            }, () => { // callback function
                return refetchedEVSTNData; // this.props.evstations; //
            });
            return refetchedEVSTNData; // null or actual data -- ideally we don't need this
            // this.checkForValidEVSTATIONData();
            // tmpevstnsdata = this.state.evstationsdata;
        } else {
            if(isLogging) utils_DbgLogger("VALID EVSTN DATA PASSED FROM PARENT = " + JSON.stringify(this.props.evstations.evstns)); // DBG
            this.setState({
                evstationsdata: this.props.evstations, // clone into child
                isEVStnDataReady: true,
            }, () => { // callback function
                return refetchedEVSTNData; // this.props.evstations; //
            });

            // this.checkForValidEVSTATIONData();
            // utils_DbgLogger("DBG : NUMBER OF EVSTATIONS Data Passed from Parent = " + this.props.evstations.evstns.length); // DBG
            // utils_DbgLogger("DBG : NUMBER OF EVSTATIONS Data Passed from Parent = " + this.props.evstations.evstns); // DBG
            // this.props.fnSetEVStationParentData(this.state.evstationsdata); // clone into child - TBD-TODO Tested
            // this.state.evstationsdata.map((evstn) => { utils_DbgLogger("DBG: INCHILD = evstationsdata = " + evstn.location_id) }); // DBG
            // tmpevstnsdata = this.state.evstations;
            // let oldObject = Object.assign({}, this.props.evstations);
            // utils_DbgLogger("DBG : NUMBER OF EVSTATIONS Data Copied to Child = " + this.state.evstationsdata.length);
        }

        /*
        if ((this.state.errorRequest === true) || (this.state.evstations === null)) {  // check after reloading data
            utils_DbgLogger("ERR : No EVSTATIONS Data : FAILED AGAIN");
        } else {
            // this.checkForValidEVSTATIONData();
            // this.state.evstationsdata.map((evstn) => { utils_DbgLogger("DBG: INCHILD = evstationsdata = " + evstn.location_id) }); // DBG
        }
        // this.props.evstations.evstns.map((evstn) => {utils_DbgLogger("DBG: INCHILD = evstations = " +  evstn.location_id)});

        // this.parseEVSTATIONSDATA();
        // this.state.evstationsdata.map((evstn) => { utils_DbgLogger("DBG: INCHILD = evstationsdata = " + evstn.location_id) }); // DBG
        // utils_DbgLogger("DBG : inside MyGoogleMap() componentDidMount() : evstationsdata = " + JSON.parse(this.state.evstationsdata)); // TBD-TODO error
*/

    } // checkAndFetchEVSTNData()


    componentWillMount() { // async
        // this.setCurrentLocation(); // TBD-TODO try to in async and enable this based on flag
        // else users might be pissed of forcing them to set current location
    } // componentDidMount()


    fetchEVSTATIONSDATA() {
        if(isLogging) utils_DbgLogger("DBG : Inside  EVSTATION() fetchEVSTATIONSDATA()"); // DBG
        this.setState({ isLoading: true });

        getEVStationDataByState('TN', 'ALL')
            .then((data) => {
                //  CRITICAL ERROR TBD-TODO
                // evstationsdata has the value of 'Error: Network Error' when API is not reacheable
                // "validate data before using
                // and error/exception handling
                // and retry attempt based on axios response object"

                // this.props.fnSetEVStationParentData(this.state.evstationsdata); // This should set parent evstations
                this.props.fnSetEVStationParentData(data); // This should set parent evstations
                // CRTICAL state objects may take a while to refelect - hence passing the inmemory object
                // utils_DbgLogger("DBG : Data From getEVStationDataByState() : " + JSON.stringify(data));
                // utils_DbgLogger("DBG : Data From getEVStationDataByState() : " + JSON.stringify(data.evstns));
                // utils_DbgLogger("DBG : Data From getEVStationDataByState() : " + JSON.parse(data)); // Error 
                this.setState({
                    //evstationsdata: JSON.stringify(data),  
                    // evstationsdata: JSON.stringify(data), // Parsed data is stored on successful fetch
                    evstationsdata: data, // JSON Object
                    errorRequest: false,
                    isvalidevstationsdata: true,
                    isLoading: false,
                }, () => {
                    // Call back function
                    // utils_DbgLogger("DBG : evstations Fetch result =" + this.state.evstationsdata); // DBG
                    // utils_DbgLogger("DBG : this.state.userObj = " + JSON.stringify(this.state.userObj)); // DBG - Wokrs fine but takes longer this call bak ensures that I get immediate value after async state update
                    return data; // return this.state.evstationsdata;
                });

                // tmpevstnsdata = this.state.evstationsdata;
                // this.checkForValidEVSTATIONData(); // dbg
            })
            .catch((error) => {
                // if(isLogging) utils_DbgLogger("ERR: EVSTNS Data Fetch : " + error); // DBG
                this.setState({
                    evstationsdata: null,
                    errorRequest: true,
                    isvalidevstationsdata: false,
                    isLoading: false,
                }, () => { // Call back function
                    utils_ErrorLogger("Error while refetching EVSTN Data from child : " + error);
                    return null;
                });
            });
    } // fetchEVSTATIONSDATA()


    /*
        // async is causing some issues ???
        getEVSTATIONSDATA() { //
            utils_DbgLogger("DBG : Inside  EVSTATION() getEVSTATIONSDATA()");
            this.setState({
                isLoading: true,
                isvalidevstationsdata: false
            });
    
    
            getEVStationDataByState('TN', 'ALL')
                .then((data) => {
                    this.setState({
                        evstations: data, /// Are we allowed to set at parent ?? not working
                        // props.evstations: data, // TBD-TODO
                        evstationsdata: data, // child level data setting
                        filteredevstations: data,
                        errorRequest: false,
                        isvalidevstationsdata: true,
                        isLoading: false,
                    });
                    this.props.fnSetEVStationParentData(data); // TBD-TODO - This should parent evstations
                    utils_DbgLogger("DBG : evstations Fetch result =" + this.state.evstationsdata); // TBD-TODO this is causing some JSON exception /??
                    // utils_DbgLogger("DBG : evstations Fetch result =" + JSON.parse(this.state.evstationsdata)); // TBD-TODO this is causing some JSON exception /??
                })
                .catch((error) => {
                    utils_DbgLogger("ERR: EVSTNS Data Fetch : " + error);
                    this.setState({
                        evstations: null,
                        filteredevstations: null,
                        errorRequest: true,
                        isvalidevstationsdata: false,
                        isLoading: false,
                    });
                });
        } // getEVSTATIONSDATA()
        */



    /*
        parseEVSTATIONSDATA() { //
            utils_DbgLogger("DBG : NUMBER OF Objects in evstationsdata : " + this.state.evstationsdata.length);
            utils_DbgLogger("DBG : NUMBER OF Objects in evstationsdata : " + JSON.parse(this.state.evstationsdata));
            // this.props.evstations.evstns.map((evstn) => {utils_DbgLogger("DBG: INCHILD = evstations = " +  evstn.location_id)});
            this.state.evstationsdata.map((evstn, indexid) => {
                utils_DbgLogger("DBG: INCHILD = evstations = " + evstn.location_id);
            });
        } // parseEVSTATIONSDATA()
        */

    setEVMapDisplay(newDisplayStatus) {
        if (true === this.state.mapApiLoaded) {
            this.setState({ shopMapComponent: true });
        } else {
            this.setState({ shopMapComponent: false });
        }
        if(isLogging) utils_DbgLogger("DBG: Map Component Display Status = " + this.state.shopMapComponent);  // DBG
    }

/* FUTURE
    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }

    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
        this._generateAddress();
    }*/

    _onChange = ({ center, zoom }) => {
        this.setState({
            center: center,
            zoom: zoom,
        });
    }

    _onClick = (value) => {
        this.setState({
            lat: value.lat,
            lng: value.lng
        });
    }

    apiHasLoaded = (map, maps) => {
        // utils_DbgLogger("DBG : Inside apiHasLoaded()");
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
        // this.setCurrentLocation(); // works fine - for now commented - future
        // this._generateAddress(); // TBD-TODO Analytics
        // this.setEVMapDisplay(true);   // TBD-TODO tested
    };


    addPlace = (place) => {
        this.setState({
            // places: [place],
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
        // this._generateAddress(); FUTURE
    };

    /* FUTURE
    _generateAddress() {
        const {
            mapApi
        } = this.state;
        const geocoder = new mapApi.Geocoder();
        geocoder.geocode({ 'location': { lat: this.state.lat, lng: this.state.lng } }, (results, status) => {
            // utils_DbgLogger(results); // DBG
            // utils_DbgLogger(status);  // DBG
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.setState({ address: results[0].formatted_address });
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    } */

    /* FUTURE
    // Get Current Location Coordinates
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    currentLocationLat: position.coords.latitude,
                    currentLocationLng: position.coords.longitude,
                });
                // utils_DbgLogger("DBG : CurrentUserLocation = " + this.state.currentLocationLat + " : " + this.state.currentLocationLng); // DBG
            });
        }
    }
    */

    //  onClick={() => { this.setState({showingInfoWindow: !this.state.showingInfoWindow}); }}

    handleClickOnMaps = (value) => {
        // utils_DbgLogger("DBG : clicked on map");
        // utils_DbgLogger("DBG : clicked on map. IschildClicked = " + this.state.clickedOnChild);
        // utils_DbgLogger("DBG : Previous = " + this.state.prevMarker + " : Curent = " + this.state.selectedMarker);
        /// clickedOnChild
        // prevMarker
        // selectedMarker
        /*
        if (false === this.state.clickedOnChild) {
            this.setState({
                showingInfoWindow: false,
                // clickedOnChild: false,
                lat: value.lat,
                lng: value.lng,
                
            });
        } else */
        // if ( (true === this.state.showingInfoWindow ) && (false === this.state.clickedOnChild) ) {
        if (true === this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                clickedOnChild: false,
                lat: value.lat,
                lng: value.lng
            });
        }

        /*  
          else {
              this.setState({
                  showingInfoWindow: true,
                  // clickedOnChild: false,
                  lat: value.lat,
                  lng: value.lng,
                  
              });
      
          }
      */

    }; // handleClickOnMaps







    // onClick={()=> {this.setState(showingInfoWindow = !this.state.showingInfoWindow)}}
    // />
    // {this.state.showingInfoWindow && <InfoWindow place={evstn} />}

    // lMarkerID
    onMarkerClick = (evstn) => { // (props, marker, e) =>
        // places, mapApiLoaded, mapInstance, mapApi, evstations, selectedMarker
        // utils_DbgLogger("DBG : Marker was clicked At ID : "  + evstn.location_id ) ; // this is fine we are gettting the marker

        if (evstn !== null) {
            this.setState({
                // showingInfoWindow: false,
                prevMarker: this.state.selectedMarker,
                selectedMarker: evstn.id,
                // showingInfoWindow: !this.state.showingInfoWindow,
                locationInfo: evstn,
                iwID: evstn.id,
                iwName: evstn.nm,
                iWDetails: evstn.nm,
                clickedOnChild: true,
                showingInfoWindow: true,
            }, () => { // callback for immediate action afer sysn setState() operation
                // selectedMarker
                // GA GAEventTracking = (category, action, label, value)
                GAEventTracking("MAPS-MARKER-CLICK",
                    "MAPS-MARKER-CLICK-" + this.state.showingInfoWindow,
                    evstn.nm,
                    evstn.id);
                // utils_DbgLogger("DBG : prevMarker ID     : " + this.state.prevMarker // DBG
                //     + " Current selectedMarker ID : " + this.state.selectedMarker
                //    + "selectedMarker Name : " + this.state.iwName); // this is fine we are gettting the marker
                return evstn;
            }); // TBD-TODO add local inmemeory object sine setState() is async
            // utils_DbgLogger("DBG : selectedMarker ID : " + this.state.selectedMarker); // this is fine we are gettting the marker
            // utils_DbgLogger("DBG : selectedMarker Name : " + this.state.iwName); // this is fine we are gettting the marker
        }

        /*
          if (true === this.setState.showingInfoWindow ) {      // if open then display IndoWindow
            utils_DbgLogger(this.state.selectedMarker + " InfoWindow Open") ; 
            this.setState({
                showingInfoWindow : false,
            });
          } else if ((undefined === this.state.showingInfoWindow ) || (false === this.state.showingInfoWindow ) ){          // close InfoWindow
            utils_DbgLogger(this.state.selectedMarker + " InfoWindow Close") ; 
            this.setState({
                showingInfoWindow : true,
            });
          // time to take actions on marker click
          // <InfoWindow />
          // _onChildClick(cluster.properties.crimeId, longitude, latitude);
        }
      */


    }



// FUTURE
    // shouldComponentUpdate(nextProps, nextState) {
    //     return true;
        // console.log("DBG : shouldComponentUpdate()"); // dbg
        // this.state.center.lat 
        // if(this.props.center.lat === nextProps.center.lat){
            // if (this.state == nextState) {
        // if(this.state.center.lat  === nextState.center.lat ){
        //   return false;
        // }else{
        //   return true;
        // }
    //   }


    /*
    
    addEVStnMarkers = () => {
    
       return( 
            tmpevstnsdata.evstns.length && tmpevstnsdata.evstns.map((evstn, i) => { 
                return (
          <Marker key={evstn.location_id} text={evstn.name} lat={evstn.latitude} lng={evstn.longitude } />
          )
        } )
      
      )
    };
    
     
    
    // tmpevstnsdata
    // {"location_id":"2241249","name":"Sky Rocca Diner","latitude":"13.0318485","longitude":"80.241973","stateid":"33"},
         
    <Marker lat={13.0318485} lng={80.241973} text={"t1"} />
    <Marker lat={13.007826} lng={80.2161678} text={"t2"} />
     
      {
          tmpevstnsdata.evstns.length && tmpevstnsdata.evstns.map((evstn, i) => { 
              return (
        <Marker key={evstn.id} text={evstn.name} position={{ lat: evstn.latitude, lng: evstn.longitude }} />
        )
      } )
    }
      */

    render() {
        const {
            mapApiLoaded, mapInstance, mapApi, shopMapComponent, // map init load display status
            evstationsdata,
            selectedMarker, locationInfo, showingInfoWindow,
            currentLocationLat, currentLocationLng, // current location
            // prevMarker, isAnyMarkerOpen, places, evstations,
        } = this.state;

        // tbd-todo
        // CRITICAL
        // evstationsdata has the value of 'Error: Network Error' when API is not reacheable
        // Hence there is a need to validate data before using

        // if (isLogging) utils_DbgLogger("DBG :  this.props.evstations.evstns.length = "  + this.props.evstations.evstns.length ); 
        // coming as null


        /*
         this.setState({
                evstationsdata: this.props.evstations,
        */
       
                if (isLogging) utils_DbgLogger("DBG : inside MyGoogleMap() render() : shopMapComponent = " +
                shopMapComponent + " : evstationsdata count = " + this.inMemEVSTNObj); // .evstns.length);  // DBG

        // if (isLogging) utils_DbgLogger("DBG :  evstationsdata.evstns.length = "  + evstationsdata.evstns.length ); // undefined

        let enStnsToDisplay = this.props.evstations || evstationsdata; //  this.state.evstationsdata; // parent or child state
        if ((enStnsToDisplay === undefined) || (enStnsToDisplay === null)) { //  || (enStnsToDisplay.evstns.length <= 0)) {
            // Invalid or null or empty evstationsdata
            if ((this.inMemEVSTNObj !== undefined) || (this.inMemEVSTNObj !== null) || (this.inMemEVSTNObj.evstns.length > 0)) {
                enStnsToDisplay = this.inMemEVSTNObj; // something wrong with the state object hence leveraging in-mem obhects
                if (isLogging) utils_DbgLogger("DBG : enStnsToDisplay is set with local mem object");
            }
        }

        if (isLogging) utils_DbgLogger("DBG : inside MyGoogleMap() render() : shopMapComponent = " +
         shopMapComponent + " : evstationsdata count = " + JSON.stringify(enStnsToDisplay));  // DBG
// undefeind - enStnsToDisplay.evstns.length  enStnsToDisplay.evstns.length 

        /*
                if ( (this.state.isEVStnDataReady === false) || (enStnsToDisplay === undefined) || (enStnsToDisplay === null)) { 
                    return (
                        <>
                        <Typography>
                            Please Wait ... Preparing Page for your comfortable Ride.
                        </Typography>
                        </>
                    );
                } else if ((this.state.isEVStnDataReady === true) && (enStnsToDisplay !== undefined) && (enStnsToDisplay !== null) &&
                            (enStnsToDisplay.evstns.length)) */
        // {

        // tmpevstnsdata = this.state.evstationsdata; // reinit whenever we render - safety belt
        // utils_DbgLogger("DBG : inside MyGoogleMap() render() : tmpevstnsdata = " + tmpevstnsdata); // null
        // utils_DbgLogger("DBG : inside MyGoogleMap() render() : tmpevstnsdata = " + JSON.parse(tmpevstnsdata)); // TBD-TODO
        /// this.CreateMarkers(); // ??? TBD-TODO
        // <Markers />
        // onLoad={this.CreateMarkers()}
        /*
                    onChildMouseDown={this.onMarkerInteraction}
                    onChildMouseUp={this.onMarkerInteractionMouseUp}
                    onChildMouseMove={this.onMarkerInteraction}
                    onChildClick={this.onMarkerClick}
                    onClick={this._onClick}



                    this.state.showingInfoWindow ? x : y
                    : null   



                    onChildMouseUp={() => { this.setState({locationInfo: null}); }}
                    onDrag={() => { this.setState({locationInfo: null}); }}
                    onClick={() => { this.setState({locationInfo: null}); }}
                    
                    onClick={() => { this.setState({showingInfoWindow: !this.state.showingInfoWindow}); }}

                                            onZoomChanged={() => { utils_DbgLogger("DBG : Zoom changed") }}

                     <Wrapper>  </Wrapper>
 <div> <h2>Dynamic Title - {this.props.title} </h2> </div>

 */



        return (
            <>
                <div className="main-wrapper">
                    {shopMapComponent && mapApiLoaded && (
                        <div>
                            <AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />
                        </div>
                    )}
                    <GoogleMapReact
                        center={this.state.center}
                        zoom={this.state.zoom}
                        draggable={this.state.draggable}
                        onChange={this._onChange}
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                            libraries: ['places', 'geometry'],
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                        onClick={this.handleClickOnMaps}
                    >
                        {
                            shopMapComponent && mapApiLoaded && currentLocationLat && currentLocationLng &&
                            <CurrentLocationMarker
                                key="CurrentUserLocation"
                                lat={this.state.currentLocationLat}
                                lng={this.state.currentLocationLng}
                                text="Current Location" />
                        }
                        {
                            shopMapComponent && mapApiLoaded &&
                            enStnsToDisplay && enStnsToDisplay.evstns && enStnsToDisplay.evstns.length &&
                            enStnsToDisplay.evstns.map((evstn) => {
                                return (
                                    <Marker key={evstn.id} text={evstn.nm} info={evstn}
                                        lat={evstn.lt} lng={evstn.ln} 
                                        onClick={() => { this.onMarkerClick(evstn) }}
                                    />
                                )
                            })
                        }
                    </GoogleMapReact>
                    {shopMapComponent && mapApiLoaded && selectedMarker && showingInfoWindow && <LocationInfoBox info={locationInfo} />}
                    {shopMapComponent && selectedMarker && <EVstnSnippet info={locationInfo}> </EVstnSnippet>}
                </div>
            </>
        );
        // } // else block{}
    }
} // EVStationsPageContent
export default EVStationsPageContent;



/*




                      ////// WORKS FINE
  {
                            shopMapComponent && mapApiLoaded &&
                            evstationsdata && evstationsdata.evstns && evstationsdata.evstns.length &&
                            evstationsdata.evstns.map((evstn, i) => {
                                return (
                                    <Marker key={evstn.location_id} text={evstn.name}
                                        lat={evstn.latitude} lng={evstn.longitude}
                                        onClick={() => { this.onMarkerClick(evstn) }}
                                    />
                                )
                            })
                        }
                      ////// WORKS FINE



                        ////// WORKS FINE
                        {
                            shopMapComponent && mapApiLoaded && tmpevstnsdata.evstns.length &&
                            tmpevstnsdata.evstns.map((evstn, i) => {
                                return (
                                    <Marker key={evstn.location_id} text={evstn.name}
                                        lat={evstn.latitude} lng={evstn.longitude}
                                        onClick={() => { this.onMarkerClick(evstn) }}
                                    />
                                )
                            })
                        }
                        ////// WORKS FINE



// const EVStationsPageContent = ({ EVStations, supportedgeostateids, title }) => {
const EVStationsPageContent = ({ supportedgeostateids, evstations, setevstations, title, settitle }) => {

    if ( (evstations === undefined) || (evstations === null)
     || (evstations.evstns === undefined) || (evstations.evstns.length  <= 0)
     )
    {
        // User has landed directly on this url.
        // we have to fetch all the data and set it to global context
        utils_DbgLogger("ERR : No evstations Data... Attempting to fetch");

        getEVStationDataByState('TN', 'ALL')
        .then((data) => {
            evstations = data;
          setevstations(data);
          // setfilteredevstations(data);
          // setErrorRequest(false);
          // setIsLoading(false);
          // settitle('VENKATESH Title Props through state'); // dbg
          utils_DbgLogger("DBG setevstations is set with = " + JSON.stringify(data));
        })
        .catch((error) => {
          utils_DbgLogger("ERR: EVSTNS Data Fetch");
          setevstations([]);
          // setIsLoading(false);
          // setErrorRequest(true);
        });


     }







    // supportedgeostateids.map((stateid) => {utils_DbgLogger("DBG: " + stateid.nm)});
    evstations.evstns.map((evstn) => {utils_DbgLogger("DBG: EVSTATION ID = " +  evstn.location_id)});

  let supportedStates = '';
  // this.props.supportedgeostateids.map((stateid) => {
  supportedgeostateids.map((stateid) => {
      let stateName = stateid.nm;
      supportedStates += stateName;
      supportedStates += ' : ';
  });
  utils_DbgLogger("DBG: supportedStates = " + supportedStates);

  settitle("THIS IS THE NEW STATE INFORMATION FOR SETTITLE");

  if (true) {
  return (
<div className="main-wrapper"> <EVStationsMap evstations={evstations} /> </div>
  )
} else {
    return (
        <>
        <h2> New Title - {title} </h2>
        <EVStationsMap supportedStates={supportedStates} ></EVStationsMap>
        </>
    );
}

}; // EVStations
export default EVStationsPageContent;
*/




/*
            <MyGoogleMap evstations={evstations}></MyGoogleMap>

            <h1> Welcome to EVSTATIONS LISTING PAGE - d - {supportedStates}</h1>


class EVStationsPageContent extends MyGoogleMap {


    render() {
        return <h1>Hello, {this.props.title}</h1>;
      }
} // class EVStationsPageContent
export default EVStationsPageContent;

*/


/*
DBG : States API Data = [{"id":34,"nm":"Pondicherry","sn":"PY"},{"id":33,"nm":"Tamil Nadu","sn":"TN"}]
<p>EV Fast Charge Stations* -  Dynamic - {title}</p>
<Typography paragraph>
                Click <Link to="/Contactus">here</Link> to contactus or to report any issues.
            </Typography>

DBG : EVSTN API Data = {"evstns":[
    {"location_id":"2241249","name":"Sky Rocca Diner","latitude":"13.0318485","longitude":"80.241973","stateid":"33"},
    {"location_id":"224150","name":"High Five","latitude":"13.007826","longitude":"80.2161678","stateid":"33"}]}


<Route exact path="/EVStationsMap" key="EVStations2"
render={props => (<EVStationsPageContent {...props} title={`Props through render`}
supportedgeostateids={supportedgeostateids} evstations={evstations}
/>)}>

<MyGoogleMap
render={props => (<EVStationsPageContent {...props} title={title}}
supportedgeostateids={supportedgeostateids} evstations={evstations}
/>)}
>

            </MyGoogleMap>

<Route exact path="/EVStationsMap" key="EVStations2"
render={props => (<EVStationsPageContent {...props} title={`Props through render`}
supportedgeostateids={supportedgeostateids} evstations={evstations}
/>)}
>


{
  "data": {
    "evstns": [
      {
        "location_id": "2241249",
        "name": "Sky Rocca Diner",
        "latitude": "13.0318485",
        "longitude": "80.241973",
        "stateid": "33"
      },
      {
        "location_id": "224150",
        "name": "High Five",
        "latitude": "13.007826",
        "longitude": "80.2161678",
        "stateid": "33"
      }
    ]
  },
  "status": 200,
  "statusText": "OK",
  "headers": {
    "content-length": "233",
    "content-type": "application/json; charset=utf-8"
  },
  "config": {
    "url": "/getevstninfo?sid=&dtls=0",
    "method": "get",
    "headers": {
      "Accept": "application/json, text/plain, * / *" // TBD-TODO remove whitespace betwen * / *
    },
    "baseURL": "http://localhost:7000",
    "transformRequest": [
      null
    ],
    "transformResponse": [
      null
    ],
    "timeout": 0,
    "xsrfCookieName": "XSRF-TOKEN",
    "xsrfHeaderName": "X-XSRF-TOKEN",
    "maxContentLength": -1,
    "maxBodyLength": -1,
    "transitional": {
      "silentJSONParsing": true,
      "forcedJSONParsing": true,
      "clarifyTimeoutError": false
    }
  },
  "request": {}
}



*/



// EVStations.jsx