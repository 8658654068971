// ****************************** //
// https://github.com/shsngit/evstn2021sep
// App.jsx
import {
  DEFAULT_API_DATA_SETTINGS, DEFAULT_USEROBJ, coookieConcentMessage,
  DEFAULT_GA_SETTINGS, DEFAULT_APP
} from './config/globals';

// React
import React, { Component } from 'react';

// routes
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom'; // Router Redirect
import NavigationBar from './components/header/NavigationBar'; // , { NavigationRouteLinks}
// import Routes from './components/header/Routes';

// UI
import Toolbar from '@material-ui/core/Toolbar';
// import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import Zoom from '@material-ui/core/Zoom';
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { ScrollToTop } from './components/Pages/ScrollToTop';



// GA Analytics
import { createBrowserHistory } from 'history';
// import ReactGA from 'react-ga'; // used indirectly by history - tbd-todo
import CookieConsent from 'react-cookie-consent'; //, { getCookieConsentValue, Cookies } 
import { GAInit, GAPageViewTracking } from './components/analytics/Analytics'; // GAExceptionTracking
// User
import { v4 as uuidv4, NIL as NULL_UUID } from 'uuid';
// import { v4 as uuidv4, validate as uuidValidate, version as uuidVersion, NIL as NULL_UUID } from 'uuid';
// import { Base64 } from 'js-base64';
// import validator from 'validator';
// import isBase64 from 'validator/lib/isBase64';

// API Data
import { getEVStationDataByState, userSignInAPI } from './components/data/GetData';

// Utils
import {
  utils_ErrorLogger, utils_generateGaClientId, utils_DecodeString,
} from './components/utils/Utils'; // utils_DbgLogger, utils_UUIDValidateV4,

// EVStations
import EVStationsPageContent from './components/Pages/EVStations';
import AboutusPageContent from './components/Pages/Aboutus';
import HomePageContent from './components/Pages/Home';
import ContactusPageContent from './components/Pages/Contactus';
import PartnersPageContent from './components/Pages/Partners';
import ProductsPageContent from './components/Pages/Products';
import TermsPageContent from './components/Pages/Terms';
import PluggedOut404PageContent from './components/Pages/PluggedOut404';



// ****************************** //
// CSS and Styles
import './App.css';
// ****************************** //



// ****************************** //
// import { FormatAlignCenterSharp } from '@material-ui/icons';
// import axios from 'axios';
// import { getEVGeoStatesNamesData, getEVStationDataByState, ApiDataFetcher, dataFetch } from './components/data/GetData';
// import {initGA, PageViewGATracking, EventGATracking} from './components/analytics/useGaTracker';
// import { BrowserRouter, Switch, Route, Router, Redirect, useLocation,  Router, Switch, Route } from 'react-router-dom';
// import MyGoogleMap from './components/MyGoogleMap';
// import Typography from '@material-ui/core/Typography';
// import { Link } from 'react-router-dom';
// import { DEFAULT_MAP_SETTINGS, DEFAULT_GA_SETTINGS, DEFAULT_API_DATA_SETTINGS } from './config/globals';
// import MyGoogleMap from './components/MyGoogleMap';
// import logo from './logo.svg';
// import styled from 'styled-components';
// import React, { Component, useState, useEffect, createRef } from 'react';
// ****************************** //

// ****************************** //
const history = createBrowserHistory(); // TBD-TODO do we need history for GA to work fine ????
history.listen(location => {
  GAPageViewTracking(location);
});
// ****************************** //



// function NoMatch(lUserObj) { lUUID={lUserObj.user.uuid}
function NoMatch() {
  let location = useLocation();
  location = location.pathname;
  // GAExceptionTracking(false, "", location, "", "PAGEVIEW", "HTTP-ERR-404"); // tbd-todo
  return (
    <div>
      <h3>
        No match for {location}
      </h3>
      <PluggedOut404PageContent  />
    </div>
  );
}

// function App() {
// const App = () => {
class App extends Component {

  // ****************************** //
  constructor(props) {
    super(props);
    this.state = {
      errorRequest: false,
      isLoading: false,
      supportedgeostateids: null,
      filteredevstations: null,
      evstations: null,
      getEVStationsURL: DEFAULT_API_DATA_SETTINGS.EVSTNDATAHOST + DEFAULT_API_DATA_SETTINGS.EVSTNGETEVSTNDATABYSTATEIDSLUG,
      isValidToken: false,
      isValidUser: false,
      isGuestUser: false,
      isReturningUser: false,
      userObj: '',
    };
  } // constructor()
  // ****************************** //

  // ****************************** //
  // Get details from browser local storage
  getUserDetailsFromLocal() {
    const levpsuser = localStorage.getItem(DEFAULT_APP.LOCALSTORAGE_USER_KEYNAME); //  'evpsuser'
    // console.log("DBG : getAuthDetailsFromLocal() : levpsuser = " + levpsuser);
    return (levpsuser);
  } // getAuthDetailsFromLocal()
  // ****************************** //



  // ****************************** //
  // Save Encoded User details in browser local storage
  // tbd-todo - not that safe - find other alternate and secure options 
  setUserDetailsIntoLocal(encodedUserInfo) {
    // console.log("DBG : setUserDetailsIntoLocal() : "); // DBG
    // var tmpUser = 'eyJ1c2VyIjp7InVzZXJJZCI6IjVjYzg0ZmQxLWQ0MmMtNGQ3OS1hYTkzLWU4OTgyY2IxM2U1MyIsInV1aWQiOiI1Y2M4NGZkMS1kNDJjLTRkNzktYWE5My1lODk4MmNiMTNlNTMiLCJjaWQiOiIyNDI2OTQ2MjMzLjE2MzExOTg4OTkiLCJuYW1lIjoiR3Vlc3RVc2VyIDI0MjY5NDYyMzMuMTYzMTE5ODg5OSIsInVzZXJuYW1lIjoiZ3Vlc3R1c2VyLjI0MjY5NDYyMzMuMTYzMTE5ODg5OSIsImVtYWlsIjoiZ3Vlc3R1c2VyLjI0MjY5NDYyMzMuMTYzMTE5ODg5OUBldnBpdHN0b3B1c2VyLmNvbSJ9LCJ0b2tlbiI6ImV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUoxYzJWeVNXUWlPaUkxWTJNNE5HWmtNUzFrTkRKakxUUmtOemt0WVdFNU15MWxPRGs0TW1OaU1UTmxOVE1pTENKMWRXbGtJam9pTldOak9EUm1aREV0WkRReVl5MDBaRGM1TFdGaE9UTXRaVGc1T0RKallqRXpaVFV6SWl3aVkybGtJam9pTWpReU5qazBOakl6TXk0eE5qTXhNVGs0T0RrNUlpd2libUZ0WlNJNklrZDFaWE4wVlhObGNpQXlOREkyT1RRMk1qTXpMakUyTXpFeE9UZzRPVGtpTENKMWMyVnlibUZ0WlNJNkltZDFaWE4wZFhObGNpNHlOREkyT1RRMk1qTXpMakUyTXpFeE9UZzRPVGtpTENKbGJXRnBiQ0k2SW1kMVpYTjBkWE5sY2k0eU5ESTJPVFEyTWpNekxqRTJNekV4T1RnNE9UbEFaWFp3YVhSemRHOXdkWE5sY2k1amIyMGlMQ0pwWVhRaU9qRTJNekV5TkRrNE56SXNJbVY0Y0NJNk1UWTJORFF4TnpFeU1qQTFNWDAuclF1QjFyOTJVLV9McnRRbDJMQm4wd0E3OHZUbW01R1c4UzZMM2NHVVFwMCIsImV4cGlyZWRBdCI6MTYzMTI0OTg3MjE4Mn0=';
    localStorage.setItem(DEFAULT_APP.LOCALSTORAGE_USER_KEYNAME, encodedUserInfo); // 'evpsuser'
    return (this.getUserDetailsFromLocal()); // return stored object 
  } // setUserDetailsIntoLocal()


  // ****************************** //
  initUserState() {
    // if (this.state.isGuestUser) {
    if (!this.state.isValidUser) { // this check may not make sense - tbd-todo
      let lUserObj = this.setUserInfo();
      if (lUserObj === null) {
        console.log("ERR : User Object");
        this.setState({
          isValidUser: false,   // Nothing much can be done
        });
        return null;
      } else if (lUserObj.user.isReturningUser === true) { // retruning user
        // returning user neeed to verify JWT token tbd-todo
        return lUserObj; // useful at caller level since state userobj may not be ready yet
      } else { // new user
        // console.log("DBG : setUserInfo returned lUserObj = " + JSON.stringify(lUserObj));
        
        // let lUUID = lUserObj.user.uuid;
        // TBD-TODO - set this in global userobj

        // console.log("DBG : uuid = " + lUUID); // DBG Fresh UUID for new user
        // console.log("DBG : cid = " + lUserObj.user.cid); // DBG

        // We have an inmemory object - do an aysnc signin operation to get token
        // and store that response in localstorage or further reuse in future sessions.
        /*
        const signInObj = await this.signInUserAndGetJWTToken(lUserObj);
 
        if ((signInObj === undefined) || (signInObj === null)) {
          console.log("ERR : signin failed");
        } else {
          console.log("DBG : signin = " + signInObj );
          const lValidUser = this.processAuthResponse(lUUID, signInObj); // check return value before proceeding
          if ((lValidUser === NULL_UUID) || (lValidUser === null) || (lValidUser !== lUUID) ) {
            console.log("ERR : INVALID User Object");
          } else {
            console.log("DBG : VALID User Object");
          }  
        }
        */

        this.signInUserAndGetJWTToken(lUserObj, false) // fresh new user 
          .then((signInObj) => {
            // TBD-TODO signInObj is arfiving here as undefined
            // console.log("DBG : signin = " + signInObj ); // TBD-TODO DBG : signin = undefined - async/await issue I still get undefined

            /*
                      const lValidUser = this.processAuthResponse(lUUID, signInObj); // check return value before proceeding
                      if ((lValidUser === NULL_UUID) || (lValidUser === null) || (lValidUser !== lUUID) ) {
                        console.log("ERR : INVALID User Object");
                      } else {
                        console.log("DBG : VALID User Object");
                      }  
                      */
          })
          .catch((e) => {
            console.log("ERR : signin failed = " + e); // TBD-TODO Async Await issue 
            // though sign-in succeeds I still get to this catch block
          })

        return lUserObj; // useful at caller level since state userobj may not be ready yet
        // TBD-TODO once async await is sorted for userobj this retrun has to be revisited
      }
    }
  } // initUserState()
  // ****************************** //



  // ****************************** //
  componentDidMount() {
    // console.log("DBG : Inside  App() componentDidMount()"); // DBG

    this.fetchEVSTATIONSDATA();
    let lUserObj = this.initUserState(); // Initialize User Status - guest or returning user on no user

    if ((lUserObj !== undefined) || (lUserObj !== null)) {
      DEFAULT_GA_SETTINGS.gaUUID = lUserObj.user.uuid;
      DEFAULT_GA_SETTINGS.gaCID = lUserObj.user.cid;
    }

    if (!window.GA_INITIALIZED) {
      // GA - as a last one    
      GAInit(); // GA init - TBD-TODO - Not sure whether this is working
      window.GA_INITIALIZED = true;
    }
  } // componentDidMount()
  // ****************************** //



  // TBD-TODO
  // JWT Token verification - need to do at server side /verifyToken API Call

  // send GA
  // ReactGA.pageview(window.location.pathname); // GA
  // PageViewGATracking(window.location.pathname); // GA


  // ****************************** //
  validateUserDetailsFromLocal(lUserObj) {
    // console.log("DBG : validateUserDetailsFromLocal() : lUserObj = " + lUserObj);

    try {
      /*
      var b = new Buffer.from(lUserObj, 'base64')
      lUserDecodedObj = b.toString();
      console.log("DBG : lUserObj Decoded = " + lUserDecodedObj); // JS or JSON Object
      return lUserDecodedObj; */

      let lUserDecodedObj = utils_DecodeString(lUserObj);
      // console.log("DBG : lUserObj Decoded = " + lUserDecodedObj); // JS or JSON Object DBG wokrs fine
      return lUserDecodedObj;
    } catch (e) {
      console.log("ERR : validateUserDetailsFromLocal() : While Decoding User Object : " + e); // DBG
      return null;
    }
  } // validateUserDetailsFromLocal()
  // ****************************** //


  // ****************************** //
  // https://stackoverflow.com/questions/41446560/react-setstate-not-updating-state
  // !!!!! CRITICAL - setState is Aysn hence await function has to be in async and the caller must have .then or try{} block to work fine
  // async  
  setUserObject(lUserStatus, lUserObj, lIsGuestUser, lIsValidToken, lIsReturningUser) {


    // console.log("DBG : setUserObject() : lUserObj = " + lUserObj); // DBG
    // let copiedObject = JSON.parse(lUserObj); // Works fine when the incoming object is stringify object 
    // console.log("DBG : setUserObject() : copiedObject = " + copiedObject); // DBG
    // console.log("DBG : setUserObject() : copiedObject = " + copiedObject.user.uuid); // DBG
    this.setState({ // !!!!! CRITICAL - setState is Aysn hence await function has to be in async and the caller must have .then or try{} block to work fine
      // userObj_user_userId: copiedObject.user.userId,
      // userObj_user_uuid: "" + copiedObject.user.uuid,
      // userObj_user_cid: copiedObject.user.cid,
      // userObj_user_name: copiedObject.user.name,
      // userObj_user_username: copiedObject.user.username,
      // userObj_user_email: copiedObject.user.email, 
      // userObj_user_isAmdin: false,
      // userObj_token: copiedObject.token,
      // userObj_expiredAt: copiedObject.expiredAt,
      // userObj: copiedObject,
      // userObj: JSON.stringify(copiedObject),
      userObj: lUserObj,
      // userObj : JSON.parse(JSON.stringify(lUserObj)),
      // userObj : JSON.stringify(JSON.parse(lUserObj)),
      // userObj : JSON.parse(lUserObj),
      isValidUser: lUserStatus,
      isGuestUser: lIsGuestUser,
      isValidToken: lIsValidToken,
      isReturningUser: lIsReturningUser
    }, () => {
      // console.log("DBG : this.state.userObj = " + JSON.stringify(this.state.userObj)); // DBG - Wokrs fine but takes longer this call bak ensures that I get immediate value after async state update
      // return this.state.userObj; // use this object since this.state.userObj is unpreditable
      return lUserObj;
    });
    return lUserObj; // TBD-todo - is this right to return alwayes before waiting for set changes.



    // .then( () => {
    // copiedObject = this.state.userObj;
    // console.log("DBG : this.state.userObj = " + JSON.stringify(copiedObject)); // DBG - 
    // return copiedObject; // use this object since this.state.userObj is unpreditable
    // console.log("DBG : this.state.userObj = " + JSON.stringify(this.state.userObj)); // DBG - works fine
    // console.log("DBG : this.state.userObj = " + this.state.userObj); // Obj

    // console.log("DBG : this.state.userObj = " + JSON.parse(this.state.userObj)); // Exception
    // console.log("DBG : this.userObj_user_uuid = " + this.state.userObj_user_uuid);
    // })
  } // setUserObject()
  // ****************************** //


  // ****************************** //
  setGuestUserObject() { // set as guest user
    // console.log("DBG : setGuestUserObject() : ");
    let guestUser = this.assembleGuestUserObject(); // guest user object
    // await 
    guestUser = this.setUserObject(true, guestUser, true, false, false); // fresh user
    // console.log('DBG : setGuestUserObject() : returned from setUserObject() guestUser = ' + JSON.stringify(guestUser)); // DBG
    return guestUser;
    /*
    .then((lUserObj) => {
      // returns userObj inmemeory object
      console.log('DBG : setGuestUserObject() : returned from setUserObject() lUserObj = ' + JSON.stringify(lUserObj));
      return lUserObj; // return this to caller
    })
    .catch((e) => {
      // setUserObject() catch tbd-todo
      return null;
    });*/


    // console.log("DBG : Guest User : this.state.userObj = " + this.state.userObj);
    // console.log("DBG : this.state.userObj = " + JSON.stringify(this.state.userObj));

    // TBD-TODO
    // Get the JWT toke and xsrf and store it in user object and set status of isValidToken

  } // setGuestUserObject()
  // ****************************** //


  // ****************************** //
  assembleGuestUserObject() {
    // console.log("DBG : assembleGuestUserObject() : ");
    let lGuestUser = DEFAULT_USEROBJ;
    // let lGuestUser = {};
    lGuestUser.user.uuid = uuidv4();             // "5cc84fd1-d42c-4d79-aa93-e8982cb13e53"
    lGuestUser.user.userId = lGuestUser.user.uuid;    // "5cc84fd1-d42c-4d79-aa93-e8982cb13e53"
    lGuestUser.user.cid = utils_generateGaClientId();  // "2426946233.1631198899",
    lGuestUser.user.name = "GuestUser " + lGuestUser.user.cid;
    lGuestUser.user.username = "guestuser." + lGuestUser.user.cid;
    lGuestUser.user.isAdmin = false;
    lGuestUser.user.isReturningUser = false; // useful
    lGuestUser.user.email = lGuestUser.user.username + "@evpitstopuser.com";
    // leave token and expiredAt as-is
    // lGuestUser = lGuestUser; // TBD-TODO Stringify or as JS object ???
    // lGuestUser = JSON.stringify(lGuestUser); // TBD-TODO Stringify or as JS object ???
    // console.log("DBG : lGuestUser = " + lGuestUser); // DBG
    return lGuestUser;
  } // assembleGuestUserObject()
  // ****************************** //


  // ****************************** //
  // async 
  setUserInfo() {
    // console.log("DBG : setUserInfo() : ");
    let lUserInfo = this.getUserDetailsFromLocal();
    if ((lUserInfo === undefined) || (lUserInfo === null)) { // || (lUserInfo.length > 10) 
      // console.log("DBG : setUserInfo() : no user info in local storage. Need to call setGuestUserObject");  // DBG
      lUserInfo = this.setGuestUserObject();
      // console.log("DBG : return value from setGuestUserObject() = " + lUserInfo); // DBG
      return lUserInfo; // We still have to set Token  - which is handled later - but we have basic user object
      // returns temp user object
    }

    // We have some local storage object let's continue the processing - returning user
    // console.log("DBG : RETURNING userInfo Localstorage = " + lUserInfo); // DBG
    let lValidatedUserInfo = this.validateUserDetailsFromLocal(lUserInfo);

    // We have some local storage - validate before using
    // this.validateUserDetailsFromLocal(lUserInfo)
    // .then((lValidatedUserInfo) => 
    if (lValidatedUserInfo !== null) {
      if (null === lValidatedUserInfo) {
        console.log("ERR : Not a Valid User Object Found in localstorage");
        // this.setGuestUserObject();  // TBD-TODO - get a fresh Guest UUID and set user details - TBD-TODO
        // handle this error at the caller decide - don't set guest user from here
      } else {
        // We have a valid Base64 string; lets set the user object
        lValidatedUserInfo = JSON.parse(lValidatedUserInfo);
        // console.log("DBG : RETURNING userInfo Localstorage UUID = " + lValidatedUserInfo.user.uuid); // DBG
        lValidatedUserInfo.user.isReturningUser = true; // returning user
        this.setUserObject(true, lValidatedUserInfo, false, false, true); // returning user
        return lValidatedUserInfo; // JSON Object returning inmemory object instead of state userobj
      }
    }
    // )
    //.catch(() => 
    else {
      console.log("ERR : Not a Valid User Object Found in localstorage");
      // this.setGuestUserObject();  // TBD-TODO - get a fresh Guest UUID and set user details
      // handle this error at the caller decide - don't set guest user from here
      return null;
    }
    // );
  } // setUserInfo()
  // ****************************** //


  // ****************************** //
  async signInUserAndGetJWTToken(lUserObj, lIsReturningUser) {
    // if (!this.state.isValidUser) return null; // Not a vlaid user object yet

    // RESUME HERE -- TBD-TODO
    // NOT ABLE TO FIND USER OBJECT
    let tmpUsr = this.state.userObj; // null;
    /*
    ALERT CRITICAL
    FOR SOME REASON state useroBj is having an empty object - nether undefined nor null
     if ((tmpUsr === undefined) || (tmpUsr === null)) {
      console.log("DBG : State userObj is nto read yet; using inmem uuid");
      tmpUsr = lUserObj;
    } */

    const paramDelimiter = "-:-";
    tmpUsr = lUserObj; // hack until state userObj is fixed
    // const tmpUsrObj = JSON.parse(this.state.userObj);
    const lCid = tmpUsr.user.cid; //  this.state.userObj.user.cid;
    const lUUID = tmpUsr.user.uuid; //this.state.userObj.user.uuid;
    var strToEncode = lCid + paramDelimiter + lUUID + paramDelimiter + "Guest" + paramDelimiter;
    var uuidenc = new Buffer.from(strToEncode).toString("base64");
    // console.log("DBG: strToEncode = " + strToEncode); // DBG
    // console.log("DBG: Encoded = " + uuidenc);  // DBG

    const signInParams = {
      username: lUUID, // '1235cc84fd1-d42c-4d79-aa93-e8982cb13e53', // this.state.userObj.uuid,
      uuid: lUUID, // '1235cc84fd1-d42c-4d79-aa93-e8982cb13e53', // this.state.userObj.uuid,
      password: uuidenc, // 'MjQyNjk0NjIzMy4xNjMxMTk4ODk5LTotNWNjODRmZDEtZDQyYy00ZDc5LWFhOTMtZTg5ODJjYjEzZTUzLTotR3Vlc3QtOi0='
    };
    // console.log("DBG : Inside signInUserAndGetJWTToken() : signInParams = " + JSON.stringify(signInParams)); // DBG
    userSignInAPI(signInParams)
      .then((response) => {
        // console.log("DBG : Data From userSignInAPI() : " + JSON.stringify(response)); // DBG
        // TBD-TODO - lot can be done based on response - future
        return this.processAuthResponse(lUUID, response, lIsReturningUser); // check return value before proceeding
        // return response;
      }) // .then((lUUID, response) => { return this.processAuthResponse(lUUID, response); })
      .catch((error) => {
        // console.log("ERR : Error From userSignInAPI() : " + JSON.stringify(error));
        utils_ErrorLogger("ERR : Error From userSignInAPI() : " + JSON.stringify(error), "App|signInUserAndGetJWTToken");
        return error; // when there are some error or API Server not reaechebale
      });
    // return null;
  } // signInUserAndGetJWTToken()
  // ****************************** //


  // ****************************** //
  processAuthResponse(lUUID, response, lIsReturningUser) {
    // console.log("DBG : response.status = " + response.status);
    if (response.status === 200) { // Auth or call Succeedeed
      // response.data.evpsuser has the base64 encoded response
      const payLoadToDecode = response.data.evpsuser;
      // console.log("DBG : Encoded User Object from server = " + payLoadToDecode); // DBG
      const newUserAuthObjStr = this.util_DecodeString(payLoadToDecode);
      // console.log('DBG : newUserAuthObj = ' + newUserAuthObjStr); // DBG
      const newUserAuthObj = JSON.parse(newUserAuthObjStr);
      const lUUIDNew = newUserAuthObj.user.uuid;
      // console.log('DBG : newUserAuthObj = ' + lUUIDNew); // DBG

      if (lUUID === lUUIDNew) { // I can't do this - WRONG  - scein the response is still encoded
        // console.log('DBG : UUID : ' + lUUIDNew + ' : Matched'); // DBG

        this.setUserObject(true, newUserAuthObj, false, false, lIsReturningUser); // in memory object to be updated for token 
        // TBD-TODO - No need to update all the user objects
        // we need to update 2 or 3 fields - so do an update rather full object setting
        this.setUserDetailsIntoLocal(payLoadToDecode); // store encoded value for next reuse
        // TBD-TODO TOKEN - Validate JWT and set isValidToken to TRUE
        return lUUIDNew;
      } else {
        console.log('ERR : UUID : ' + lUUIDNew + ' : Not Mached');
        this.setUserObject(false, newUserAuthObj, true, false, lIsReturningUser); // still a guest user with invaliduser status
        return NULL_UUID;
      }
      // return null; // return newUserAuthObj; // TBD-TODO
    } else {       // Auth Failed Not 200 or not present
      console.log("ERR : Auth Failed");
      return NULL_UUID;
    }
  } // processAuthResponse()
  // ****************************** //

  // ****************************** //
  async fetchEVSTATIONSDATA() {
    // console.log("DBG : Inside  App() fetchEVSTATIONSDATA()"); // DBG
    this.setState({ isLoading: true });

    getEVStationDataByState('TN', 'ALL')
      .then((data) => {
        // console.log("DBG : Data From getEVStationDataByState() : " + JSON.stringify(data));
        // console.log("DBG : Data From getEVStationDataByState() : " + JSON.stringify(data.evstns));
        // console.log("DBG : Data From getEVStationDataByState() : " + JSON.parse(data)); // Error 
        this.setState({
          // evstations: JSON.stringify(data), // data, Storing as JSON String object stores - but retrieval and using .map causing issues
          evstations: data, // JSON Object
          filteredevstations: this.state.evstations,
          errorRequest: false,
          isLoading: false,
        });
        // console.log("DBG : evstations Fetch result =" + this.state.evstations); // DBG
      })
      .catch((error) => {
        console.log("ERR: EVSTNS Data Fetch : " + error);
        this.setState({
          evstations: null,
          filteredevstations: null,
          errorRequest: true,
          isLoading: false,
        });
      });
  } // fetchEVSTATIONSDATA()
  // ****************************** //


  // ****************************** //
  componentDidUpdate() {
    // TBD-TODO
  }
  // ****************************** //

  // ****************************** //
  componentWillMount() {   // TBD-TODO
    // deprecated by react  - hence moved all to componentDidMount()

    // console.log("DBG : Inside  App() componentWillMount()"); // DBG
    // this.getAuthDetailsFromLocal(); // User Info GA
    // Fetch states info
    // dataFetch(DEFAULT_API_DATA_SETTINGS.EVSTNDATAHOST + DEFAULT_API_DATA_SETTINGS.EVSTNGETEVSTNDATABYSTATEIDSLUG);

    // moved these 2 to componentDidMount()
    // this.fetchEVSTATIONSDATA();
    // this.initUserState(); // Initialize User Status - guest or returning user on no user
  }
  // ****************************** //


  // ****************************** //
  componentWillUnmount() {
    // console.log("DBG : Inside  App() componentWillUnmount()");
    // TBD-TODO
  }
  // ****************************** //

  // ****************************** //
  // fnSetEVStationAPIData = (evStnnData) => {
  fnSetEVStationParentData = (evStnData) => {
    this.setState({
      evstations: evStnData,
    });
    // console.log("DBG : New EVSTATION DATA : " + JSON.stringify(evStnData)); // dbg
  }; // fnSetEVStationParentData()
  // ****************************** //


  // ****************************** //
  handleAcceptCookie() {
    // TBD-TODO - Enhance further
    /*if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    } */
  };
  // ****************************** //


  // ****************************** //
  handleDeclineCookie() {
    // TBD-TODO - Enhance further
    // For now I have disabeld 'Decline' Button
    /*
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid"); */
  };
  // ****************************** //

/*


        <Router history={history}>       </Router>

        
            <Route exact path="/EVStationsMap" key="EVStations2"
render={props => (<EVStationsPageContent {...props} title={`Props through render`}
supportedgeostateids={supportedgeostateids} evstations={evstations}
/>)}>





#####################################
WORKS FINE SEP-12-2021


      <BrowserRouter>
          <NavigationBar />
          <Switch>
  
          <Route exact path="/" component={HomePageContent}/>
          <Route exact path="/Aboutus" component={AboutusPageContent}/>
          <Route exact path="/Contactus" key="Contactus">
              <ContactusPageContent />
            </Route>
  
          <Route exact path="/EVStations" key="EVStations">
              <EVStationsPageContent
              {...this.props} {...this.state}
                evstations={this.state.evstations}
                fnSetEVStationParentData={this.fnSetEVStationParentData} />
            </Route>
         
          </Switch>
          </BrowserRouter>
          WORKS FINE SEP-12-2021
#####################################


*/
  // ****************************** //
  render() {
    
  // let location = useSearch(); // useLocation();

    return (
      <>
    


      <BrowserRouter>
          <NavigationBar />
          <Toolbar id="back-to-top-anchor" />
          <Switch>
  
          <Route exact path="/" component={HomePageContent}/>
          <Route exact path="/Aboutus" component={AboutusPageContent}/>
          <Route exact path="/Partners" component={PartnersPageContent}/>
          <Route exact path="/Products" component={ProductsPageContent}/>
          <Route exact path="/Terms" component={TermsPageContent}/>
          
          <Route exact path="/PluggedOut404" component={PluggedOut404PageContent}/>
          
          <Route exact path="/Contactus" key="Contactus">
              <ContactusPageContent />
            </Route>
  
          <Route exact path="/EVStations" key="EVStations">
              <EVStationsPageContent
              {...this.props} {...this.state}
                evstations={this.state.evstations}
                fnSetEVStationParentData={this.fnSetEVStationParentData} />
            </Route>


            <Route path="*"> <NoMatch />
        </Route>
         

          </Switch>
          </BrowserRouter>

          <ScrollToTop {...this.props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollToTop>

   
        <CookieConsent onAccept={this.handleAcceptCookie}> {coookieConcentMessage} </CookieConsent>
        <GAPageViewTracking />
      </>
    ); // return
  } // render
  // ****************************** //



  


}; // App
export default App;
// ****************************** //




/*

NoMatch


            
            <Route path="*"> 
          <Redirect to="/PluggedOut404" component={PluggedOut404PageContent} 
              uuid={this.state.userObj} />
        </Route>
         



          <Redirect to="/PluggedOut404" component={PluggedOut404PageContent}/> <<<<< IS WORKING FINE

            <Route exact path="/EVStations" key="EVStations"
render={props => (<EVStationsPageContent {...props} evstations={this.state.evstations}
  fnSetEVStationParentData={this.fnSetEVStationParentData}
/>)}>

            <Route exact path="/EVStationsMap" key="EVStations2"
render={props => (<EVStationsPageContent {...props} title={`Props through render`}
supportedgeostateids={supportedgeostateids} evstations={evstations}
/>)}>



            <Route exact path="/EVStations" key="EVStations">
              <EVStationsPageContent
                evstations={this.state.evstations}
                fnSetEVStationParentData={this.fnSetEVStationParentData} />
            </Route>

<PageViewGATracking></PageViewGATracking>
        <BrowserRouter history={history}>  </BrowserRouter>

        // enableDeclineButton
        // onDecline={this.handleDeclineCookie}

*/



/*

BrowserRouter >>>> works on navigation but not with GA
Router >>>> works on navigation & GA


<Switch>
            <Route exact path="/EVStations" key="EVStations">
              <EVStationsPageContent
                evstations={this.state.evstations}
                title={this.state.title} fnSetEVStationAPIData={this.fnSetEVStationAPIData} />
            </Route>
            <Route exact path="/Aboutus" key="Aboutus">
              <AboutusPageContent />
            </Route>
          </Switch>



  <dataFetch id="getevstationsdata" url={this.state.getEVStationsURL}></dataFetch>


EVStationsPageContent



        {Routes.map((route: any) => (
          <Route
            exact path={route.path} key={route.path}
            render={(props) => <route.component {...props} supportedgeostateids={this.state.supportedgeostateids}
              evstations={this.state.evstations} title={this.state.title}  />}
          />
        ))}



          <Route
            exact path={route.path} key={route.path}
            render={(props) => <route.component {...props} supportedgeostateids={supportedgeostateids}
              evstations={evstations} setevstations={setevstations} title={title} settitle={this.state.settitle} />}
          />


            <Route exact path={route.path} key={route.path}>
              <route.component supportedgeostateids={supportedgeostateids}
              evstations={evstations} setevstations={setevstations} title={title} settitle={settitle} />
            </Route>



        {
          <div className="main-wrapper"> <MyGoogleMap /> </div>
        }

        <Switch>
          {Routes.map((route: any) => (
            <Route exact path={route.path} key={route.path}>
              <route.component />
            </Route>
          ))}
        <Switch>

<Route
    path="/login"
    render={(props) => <Login {...props} isAuthenticated={isAuthenticated}/>}
/>


        <Switch>
          <Route exact path="/EVStations" key="EVStations"> <EVStationsPageContent title={title}/> </Route>
          <Route exact path="/Aboutus" key="Aboutus"> <AboutusPageContent /> </Route>
        </Switch>
^^^^^ THIS IS WOKRING FINE PASSING TITLE perefectly to child


<Router>
  <Route
    path='/'
    component={({children}) =>
      <MyComponent myProp={'myVal'}>{children}</MyComponent/>
    }/>
</Router>



<Typography paragraph>
                Click <Link to="/Contactus">here</Link> to contactus.
            </Typography>


<Route exact path="/EVStationsMap" key="EVStations2"
render={props => (<MyGoogleMap {...props} title={title}
supportedgeostateids={supportedgeostateids} evstations={evstations}
/>)}>
            </Route>




                        <Route exact path={route.path} key={route.path}>
              <route.component />
            </Route>


<Route exact path="" key=""> < /> </Route>

<Route exact path="/EVStations" key="EVStations"> <EVStations /> </Route>
<Route exact path="/Aboutus" key="Aboutus"> <Aboutus /> </Route>
<Route exact path="" key=""> < /> </Route>

 path: '/EVStations',
    sidebarName: 'EVStations',
    component: EVStations
  },

MyGoogleMap


<MyGoogleMap title={title}>
            </MyGoogleMap>

            <Route exact path="/EVStationsMap" key="EVStations2"
render={props => (<EVStationsPageContent {...props} title={`Props through render`}
supportedgeostateids={supportedgeostateids} evstations={evstations}
/>)}>
^^^^ WORKING FINE ^^^ PASSING PARAMS



<Route exact path="/EVStationsMap" key="EVStations2"
title={`Props through render`}
supportedgeostateids={supportedgeostateids} evstations={evstations} >
            </Route>

^^^^ IS NOT WORKING FINE ^^^ NOT PASSING PARAMS

<Route exact path="/EVStationsMap" key="EVStations2"
title={`Props through render`}
supportedgeostateids={supportedgeostateids} evstations={evstations} >



<Route exact path="/props-through-render" render={(props) => <PropsPage {...props} title={`Props through render`} />} />

      <BrowserRouter history={history}>
      >
      <Router history={history}> --- does not work

      <PageViewGATracking></PageViewGATracking>


<div className="main-wrapper"> </div>
  <MyGoogleMap />

<Header></Header>

<div className="main-wrapper">
  </div>

https://www.freakyjolly.com/google-maps-in-react-example-application/


localhost:7000/getstates
[{"id":34,"nm":"Pondicherry","sn":"PY"},{"id":33,"nm":"Tamil Nadu","sn":"TN"}]

http://localhost:7000/getevstninfo
{"evstns":[{"location_id":"2241249","name":"Sky Rocca Diner","latitude":"13.0318485","longitude":"80.241973","stateid":"33"},{"location_id":"224150","name":"High Five","latitude":"13.007826","longitude":"80.2161678","stateid":"33"}]}

*/




// import Header from './components/header/header';
// import SearchAppBar from './components/header/Hdr2';
// SearchAppBar


/*
const Wrapper2 = styled.main`
  width: 100%;
  height: 100%;
`;*/

//       <Wrapper2> </Wrapper>
//    <SearchAppBar> </SearchAppBar>




  /*
    // React States
    const [errorRequest, setErrorRequest] = useState(false); // error handling
    const [isLoading, setIsLoading] = useState(false);
    const [supportedgeostateids, setsupportedgeostateids] = useState([]);  // This is an ARRAY // Supported by EVSTN App
    const [filteredevstations, setfilteredevstations] = useState([]);
    const [evstations, setevstations] = useState([]);
    // const [evmapdisplay, setevmapdisplay] = useState(false);
    // let isMapDisplayed = false;
   
    // setEVMapDisplayStatus
    const [title, settitle] = React.useState('default title'); // DBG
    */
  /*
    useEffect(() => { // Fetch GeoStates
      console.log("DBG : Fetching geoStatesData");
      setIsLoading(true);
      getEVGeoStatesNamesData()
        .then((data) => {
          setsupportedgeostateids(data);
          setIsLoading(false);
          setErrorRequest(false);
        })
        .catch((error) => {
          setErrorRequest(true);
          setIsLoading(false);
        });
      console.log(supportedgeostateids); // debug
      setIsLoading(false);
      console.log("DBG : INIT of STATES DATA DONE");
    }, []);
   
  useEffect(() => { // Fetch GeoEvStns evstations
    setIsLoading(true);
    getEVStationDataByState('TN', 'ALL')
      .then((data) => {
        setevstations(data);
        setfilteredevstations(data);
        setErrorRequest(false);
        setIsLoading(false);
        // settitle('VENKATESH Title Props through state'); // dbg
        // console.log("DBG setevstations is set with = " + JSON.stringify(data));
        console.log("DBG setevstations is set with = " + JSON.stringify(evstations));
      })
      .catch((error) => {
        console.log("ERR: EVSTNS Data Fetch");
        setIsLoading(false);
        setErrorRequest(true);
      });
   
    setIsLoading(false);
    console.log("DBG: evstations is set with = " + evstations);
  }, []); // evstations
  // }, [evstations]); // evstations -- this is leaading infiinte loop in data fetching
  */


  /*
  useEffect(() => {
    if ( (true === errorRequest ) || (true === isLoading )) { // Either an error or not ready
      setevmapdisplay(false);
    } else {
      setevmapdisplay(true);
    }
    console.log("DBG evmapdisplay is set to = " + evmapdisplay);
  }, [evmapdisplay]);
   
  const setEVMapDisplayStatus = (newstatus) => {
    this.setState({ evmapdisplay: newstatus  });
    isMapDisplayed = newstatus;
    console.log("DBG evmapdisplay is set to = " + isMapDisplayed);
  }
  */


/*
**************** BUGS *******************
Incognito window
fresh user
updatelocalstroage
remove localstorage
refersh window
At this stage uuid difference is there - due to this api call o get fresh token is failing
**************** BUGS *******************

*/