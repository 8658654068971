// GetData.js
// import React, { useState, useEffect } from "react";
import { DEFAULT_API_DATA_SETTINGS } from '../../config/globals';
import axios from 'axios';
import { /*utils_DbgLogger,*/ utils_ErrorLogger } from '../utils/Utils';
import {DEFAULT_APP} from '../../config/globals';

// Create instance called instance
//       'content-type':'application/octet-stream',
const axiosinstance = axios.create({
    baseURL: DEFAULT_API_DATA_SETTINGS.EVSTNDATAHOST,
});
const isLogging = DEFAULT_APP.LOGGING.MOD_DATA;


// TBD-TODO
// Need to handle network error, retry , retry after x seconds etc.

// export const userSignInAPI = async (postPayLoad, postHeaders) => {
//  export const GAEventTracking = (category, action, label) => {  
export const userSignInAPI = async (postPayLoad) => {
    // console.log("DBG : Inside userSignInAPI()");

  /*
  var config = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  responseType: 'blob'
};

  var optHeaders = {
    headers: {
      'X-Custom-Header' : 'value',
      'Content-Type'    : 'multipart/form-data',                                                                                                                                                                                                               
      'Content-Type'    : 'application/json',
      'Authorization'   : 'myspecialpassword',
  }
  }


  var params = {
    paramName1: paramValue1,
    paramName2: paramValue2
  }

  const options = {
    method: 'post',
    url: DEFAULT_API_DATA_SETTINGS.EVSTNUSERSIGNINSLUG,
    data: postPayLoad,
    // xsrfCookieName: 'XSRF-TOKEN',
    // xsrfHeaderName: 'X-XSRF-TOKEN',
    // {
    //  firstName: 'Finn',
    //  lastName: 'Williams'
    // },
    // transformRequest: [(data, headers) => { // transform the data  
    //  return data;
    //}]
  };
  // axios(options);   // send the request
  */
                                                                                                                                            
  // const token=getState().auth.token;             
  // axios.post(url, params, headers)                                                                                                                                                                                                          

  /* var postOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    // responseType: 'blob'
  }; */

  // console.log('DBG : postPayLoad = ' + JSON.stringify(postPayLoad)); // DBG
  // console.log('DBG : postOptions = ' + JSON.stringify(postOptions)); // DBG
  // let result = axiosinstance.post(DEFAULT_API_DATA_SETTINGS.EVSTNUSERSIGNINSLUG, postPayLoad, postOptions)
  let result = axiosinstance.post(DEFAULT_API_DATA_SETTINGS.EVSTNUSERSIGNINSLUG, 
    postPayLoad)
    /*{
      uuid: postPayLoad.uuid,
      username: postPayLoad.username,
      password: postPayLoad.password,
    }) // ,postOptions)*/
    .then(response => {
        if (isLogging) console.log('DBG : userSignInAPI : Success', response); // response.data // DBG
        return response; // response.data
    })
    .catch(e => {
        // console.log('ERR : userSignInAPI : Error', e.response.data); // e.response.data
        utils_ErrorLogger('ERR : userSignInAPI : Obj = ' + e.response.data , "Utils|userSignInAPI");
        return e;
    });
    return result;
}; // userSignInAPI()





export const getEVGeoStatesNamesData = async () => {
    console.log("DBG : Inside getEVGeoStatesNamesData()");
    let result = axiosinstance
        .get(DEFAULT_API_DATA_SETTINGS.EVSTNGETGEOSTATESDATASLUG)
        .then((response) => {
          if (isLogging) console.log("DBG : States API Data = " + JSON.stringify(response.data));
            return response.data;
        })
        .catch((error) => {
          console.log("ERR : EVSTN States API : response = " + error);
          return error;
        });

    return result;
};

// EVSTNGETEVSTNDATABYSTATEIDSLUG
// StateIds (repeating) & isnrief or isdetailed in get params; move to post later
export const getEVStationDataByState = async (lstateid, lisbrief) => { // aysync
// export const getEVStationDataByState = (lstateid, lisbrief) => {    // sync
    // console.log("DBG : Inside getEVStationDataByState()");
    let result = axiosinstance.get(DEFAULT_API_DATA_SETTINGS.EVSTNGETEVSTNDATABYSTATEIDSLUG + '?sid=&dtls=0')  // TBD-TODO remove hard coding
        .then((response) => {
            // if (isLogging) console.log("DBG : EVSTN API : response = " + JSON.stringify(response));
            if (isLogging) console.log("DBG : EVSTN API : response.data = " + JSON.stringify(response.data));
            return response.data; // JSON Object
            // return response;
        })
        .catch((error) => {
            if (error.response) {
              // client received an error response (5xx, 4xx)
              console.log("ERR : response =" + error.response);
            } else if (error.request) {
              // TND-TODO - when there is a network error or no response from backend api
              // client never received a response, or request never left
              console.log("ERR : request =" + error.request);
            } else {
              // anything else
              console.log("ERR : EVSTN EVSTATIONS API : error = " + error);
            }
            return error;
        });

    return result; // ALERT - Removing this causing no data to return
};

/*
Cross-Origin Request Blocked: The Same Origin Policy disallows reading the remote resource at 
http://localhost:7000/getevstninfo?sid=&dtls=0. (Reason: CORS request did not succeed).

ERR : request =[object XMLHttpRequest]
https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSDidNotSucceed
*/


/*

export const useApiRequest = url => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(url)
        .then(response => {
          setIsLoaded(true);
          setData(response.data);
        })
        .catch(error => {
          setError(error);
        });
    };
    fetchData();
  }, [url]);

  return { error, isLoaded, data };
};


class ApiDataFetcher extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      data: null,
      isLoading: false,
      error: null,
    };
  }
 
  componentDidMount() {
    console.log("DBG : inside ApiDataFetcher.dataFetch()");
    this.setState({ isLoading: true });
 
    axios.get(this.props.url)
      .then(result => this.setState({
        data: result.data,
        isLoading: false
      }))
      .catch(error => this.setState({
        error,
        isLoading: false
      }));
  }
 
  render() {
    return this.props.children(this.state);
  }
} // class ApiDataFetcher



export const dataFetch = async (url) => {
  <ApiDataFetcher url={url}>
    {
     
    ({ data, isLoading, error }) => {
      if (!data) {
        return <p>No data yet ...</p>;
      }

      if (error) {
        return <p>{error.message}</p>;
      }

      if (isLoading) {
        return <p>Loading ...</p>;
      }

      return ( data
        
      );
    }}
  </ApiDataFetcher>
}

*/
/*
export const getWeatherData = async (lat, lng) => {
    try {
      if (lat && lng) {
        // const { data } = await axios.get('https://community-open-weather-map.p.rapidapi.com/find', {
        const { data } = await axios.get('http://localhost:3100/yercaud-weather.json', {
          params: { lat, lon: lng },
          headers: {
            'x-rapidapi-key': process.env.REACT_APP_RAPID_API_WEATHER_API_KEY,
            'x-rapidapi-host': 'community-open-weather-map.p.rapidapi.com',
          },
        });

        console.log(data);
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  */

// GetData.js