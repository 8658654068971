// LocationInfoBox.js
import React from 'react';


/*

@Override
    public void onInfoWindowClick(Marker marker) {
        Toast.makeText(this, "Info window clicked",
            Toast.LENGTH_SHORT).show();
    }
    */
/*
info.location_id id
info.name nm

{
        id: evStnObj.evstn_id,
        nm: evStnObj.evstn_name,
        lt: evStnObj.l_geo_addr_postals[0].geoPostalAddr_lat,
        ln: evStnObj.l_geo_addr_postals[0].geoPostalAddr_lng,
        cy: evStnObj.l_geo_addr_postals[0].l_geoaddr_cities[0].geoCity_name,
        sn: evStnObj.l_geo_addr_postals[0].l_geoaddr_cities[0].l_geoaddr_states[0].geoState_name,
        dt: displayStatus,
        st: inserviceStatus,
      };
*/


// tbd-todo ADD STATIUS HERE 

// THIS HAS THE IN-TANDEM WITH EVSTNSNIPPET.JS : EVstnSnippet() 
function LocationInfoBox({ info }) {
    // console.log("DBG : Inside LocationInfoBox()"); // DBG
    var statusToDisplay = "";
    if (info.st === true) {
        statusToDisplay = "IN SERVICE";
    } else {
        statusToDisplay = "NOT IN SERVICE";
    }
    // Depending on the status I can add RED Color etc. future TBD-TODO
    return (
        <div className="location-info" style={{ height: "30vh", width: "30%" }}>
            <h2>EV Station</h2>
            <p><strong>{info.cy}</strong></p>
            <p><strong>{info.nm}</strong></p>
            <p><strong>{statusToDisplay}</strong></p>
        </div>
    );
}

//                 <td onClick={()=> window.open(`${info.link}`, "_blank")}>EV Station Info</td>



export default LocationInfoBox;




/*
                <li>DESC: <strong>{info.description}</strong></li>
                <li>More Info : <strong>{info.link}</strong></li>

                                <a href={info.link}> Station Info</a>

                <td onClick={()=> window.open("someLink", "_blank")}>text</td>
                <td onClick={()=> window.open({info.link}, "_blank")}>EV Station Info</td>


*/

// LocationInfoBox.js