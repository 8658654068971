// Aboutus.jsx
// import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const AboutusPageContent = ({}) => {
// const AboutusPageContent = () => {
        return (
        <>
            <h1>Aboutus</h1>
            <Typography paragraph>
                We are making our attempt to help EV Customers to locate all Fast Charging, Slow Charging, Community Charging Stations in India.
                Our Website is still under beta mode (under construction).
                We need your help and support to serve you better.
            </Typography>
            <Typography paragraph>
                Our focus is to list Fast Charging Stations for Cars in South India.
                We will expand to other regions as we march forward with your help and support.
            </Typography>
            <Typography paragraph>
                Click <Link to="/Contactus">here</Link> to contactus.
                Click <Link to="/Terms">here</Link> to know about Terms of use and other polciies.
            </Typography>
        </>
    )
}; // Aboutus
export default AboutusPageContent;
// Aboutus.jsx
