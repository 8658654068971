// Terms.jsx
// import { Link } from 'react-router-dom'
import { useState } from 'react';
import { Typography, AppBar, Toolbar, Tabs, Tab, makeStyles } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import {CookiePolicyContent, PolicyTermsOfUse} from './Content/TermsPoliciesPageContent';

const useStyles = makeStyles(theme => ({
    appBarStyle: {
        background: '#000',
        zIndex: 100
    },
    toolBarStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
        }
    },
    typoStyle: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            fontSize: '3rem'
        }
    }
}))

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div>
            {value === index && (<div>{children}</div>)}
        </div>
    )
} // TabPanel()




export default function TermsPageContent() {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const clickHandler = (e, val) => {
        //console.log(val)
        setValue(val)
    }

    return (
        <div>
            <AppBar position="static" className={classes.appBarStyle}>
                <Toolbar className={classes.toolBarStyle}>
                    <Typography 
                    // className={classes.typoStyle}
                    >
                        Terms And Policies
                    </Typography>
                    <Tabs 
                    value={value} 
                    onChange={clickHandler}
                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="EVPitStop.in Terms And Policies"
                    >
                        <Tab label="Credits" />
                        <Tab label="Replacements" />
                        <Tab label="Returns" />
                        <Tab label="Cookies" />
                        <Tab label="Terms" />                        
                    </Tabs>
                </Toolbar>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Credits />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <WarrantyAndReplacements />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ReturnsAndRefunds />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <CookiePolicyContent />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <PolicyTermsOfUse />
            </TabPanel>
        </div>
    )
} // TermsPageContent()

const ReturnsAndRefunds = () => {
    return (
        <div>
            <h2>Returns And Refunds Policy</h2>
            <Typography>
                Our Return policies and simple and easy to understand. Your satisfaction is our priority.
            </Typography>
            <Typography>
                Our return policies may vary from one category to another category. Our merchant partners may have a different policy from one product to the other. We encourage you to read and understand our terms and return policies before you place your order.
            </Typography>
            <Typography>
                For all non-perishable products and product categories, wherever return is applicable, If the return is requested due to damaged goods due to transit and delivery and/or any other manufacturing defects then we will refund full order value, including shipping or any other charges.
            </Typography>
            <Typography>
                If you would like to return an non-perishable product for some other reasons then we will refund the order value (excluding forward shipping, return shipping charges and/or any other charges as communicated under product or category).
            </Typography>
            <Typography>
                Refund will be credited back to you to your Bank Account or Credit Card or Debit Card or UPI Handle. We try our best to process the refund within 10 to 14 working days, from the day we received your returned good and verified by our warehouse partners. Occasionally, refunds may be reflected after 15 working days for few orders (varies from one bank to other bank that are not in our control).
            </Typography>
            <Typography>
                For Food And Perishable we may not ask you to return the products. If you are not satisfied with the quality of the product, against manufacturing defects, and/or if you have received an opened or damaged Perishable product package then we will refund the order value. Our return or refund policy on perishable products may vary from one partner to other.
            </Typography>
            <Typography>
                You may visit and check our Terms and Policies to know about other policies and terms of use.
                Please send an email to <b><a href="mailto: crm@evpitstop.in?subject=Enquiry from App">crm@evpitstop.in</a></b> for any queries on return or refund. Please do mention your order number in the subject of your email so that we can assist you more efficiently. We generally respond in 2 to 4 working days.
            </Typography>
        </div>
    );
} // ReturnsAndRefunds()


/*
const xyz = () => {
    return (
        <div>
            <h2>xyx</h2>
            <Typography>
            </Typography>
            <Typography>
                <a style={{ display: "table-cell" }} href="https://www.freepik.com/" target="_blank">Freepik</a>
            </Typography>
            <Typography>
                Please send an email to <b><a href="mailto: crm@evpitstop.in?subject=Enquiry from App">crm@evpitstop.in</a></b> for any queries.
            </Typography>
        </div>
    );
} // xyz()
*/


const WarrantyAndReplacements = () => {
    return (
        <div>
            <h2>Warranty And Replacement Policy</h2>
            <Typography>
                To provide 100% customer satisfaction we do provide Replacement Warranty. Replacement warranty may vary from one product to other. Replacement warranty may vary from one seller to other. We encourage you to check the policies before you place your orders.
                If the product is not functional due to manufacturing defect and if the product is still under warranty period, then we may arrange for a service/repair.
            </Typography>
            <Typography>
                Even after service/repair if the product is not functional then we may arrange a replacement for your product. Please note that our suppliers and partners might have discontinued such a product over a period; in those circumstances we will try our best to arrange for an alternate product which is very similar to the functional aspects of your product, with your consent.
            </Typography>
            <Typography>
                Service, Repair or Replacement requests due to damages of any nature apart from manufacturing defects will not be encouraged.
                Please send an email to <b><a href="mailto: crm@evpitstop.in?subject=Enquiry from App on Warranty">crm@evpitstop.in</a></b> for any queries on warranty.
                Please do mention your order number in the subject of your email so that we can assist you more efficiently.
            </Typography>
            <Typography>
            </Typography>
            <Typography>
            </Typography>
        </div>
    );
}





const Credits = () => {
    return (
        <div>
            <h2>Credits</h2>
            <Typography>
                Some of the images and Icons that we use are hand picked from third party web sites.
                We sincerely thank their support for SMBs/SMEs like us.
                Wherever applicable we provide the original image source (author and/or publisher).
            </Typography>
            <Typography>
                <a style={{ display: "table-cell" }} href="https://www.flaticon.com/" target="_blank">FlatIcon</a>
            </Typography>
            <Typography>
                <a style={{ display: "table-cell" }} href="https://www.freepik.com/" target="_blank">Freepik</a>
            </Typography>
        </div>
    );
} // Credits()





/*
const utils_navigateToExternalUrl = (url: string, shouldOpenNewTab: boolean = true) => {
    shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
} // navigateToExternalUrl()
*/


// Terms.jsx
