// PluggedOut404.jsx
// import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
// import {GAExceptionTracking} from '../analytics/Analytics';
// import { Router, BrowserRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom';

/*
export const GAExceptionTracking = (isFatal, lUUID, lComponentName, lFunctionName, lOperation, lExceptionMessage) => {
*/
const PluggedOut404PageContent = ({lLocation}) => {
    // TBD-TODO - Send GA-Event about this 404 with referrer
// const PluggedOut404PageContent = () => {
    // const pageSlugUrl = window.location.pathname + window.location.search; // DBG PluggedOut404 


    // let pageSlugUrl = useLocation();
    // const pageSlugUrl = location.pathname + location.search; // DBG PluggedOut404 
    // const pageSlugUrl = lLocation.pathname();
    
    // const pageReferrer = window.location.href;
    // const docuRef = document.referrer;

    // GAExceptionTracking(false, lUUID, lComponentName, lFunctionName, lOperation, lExceptionMessage);
   // GAExceptionTracking(false, "", lLocation, "", "PAGEVIEW", "HTTP-ERR-404");


/*
<script>  
    // Create Tracker - Send to GA
	ga('create', 'UA-11111111-11');
    ga('send', {
      hitType: 'event',
      eventCategory: '404 Response',
      eventAction: window.location.href,
      eventLabel: document.referrer
 	 });
</script>
*/

        return (
        <>
            <h1>Plugged Out</h1>
            <h4>Sorry ... Requested Page {lLocation} is not found</h4>
            <Typography paragraph>
                This is embarassing to us. Sorrty about the inconvenience.
                We have reported this eror to our site administrator.
            </Typography>
            <Typography paragraph>
                Click <Link to="/">here</Link> to go back to Home Page.
                Click <Link to="/contactus">here</Link> to contactus.
            </Typography>
        </>
    )
}; // PluggedOut404
export default PluggedOut404PageContent;
// PluggedOut404.jsx
